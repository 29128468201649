/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';
import {
  allBidsByVendor,
  allJobs,
  bidSubmissionsByVendorId,
  jobInterests,
  recommendedJobs,
} from '../../../graphql/queries';
import { AWSAuthMode, IGraphQLResponse } from '../../Facility/Services/FacilityApiService';
import { CustomHeaders } from '../../../Shared/Constants/App';

export default class MarketplaceApiService {
  static async getRecommendedJobs(
    vendorId: string,
    dueDate?: string,
    limit?: number,
    pageNumber?: number,
    sortBy?: string,
    sortOrder?: string
  ) {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: recommendedJobs,
          variables: { vendorId, dueDate, limit, pageNumber, sortBy, sortOrder },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );

      let modifiedResponse: {
        matchedJobs?: any;
      } = {};

      const metadata = response.data?.recommendedJobs?.matchedJobs?.metadata;

      if (response.data?.recommendedJobs) {
        modifiedResponse = response.data?.recommendedJobs;
        modifiedResponse.matchedJobs = response.data?.recommendedJobs?.matchedJobs?.data;
      }

      return { data: modifiedResponse ?? {}, metadata: metadata, errors: [] };
    } catch (e: any) {
      return { data: [], metadata: [], errors: e.errors };
    }
  }

  static async getBidsInProgress(
    vendorId: string,
    pageNumber?: number,
    limit?: number,
    sortBy?: string,
    sortOrder?: string
  ) {
    try {
      const bidsByVendorResponse: IGraphQLResponse = await API.graphql(
        {
          query: allBidsByVendor,
          variables: { vendorId, pageNumber, limit, sortBy, sortOrder },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      let modifiedResponse: {
        matchedFacilities?: any;
        matchedJobs?: any;
        matchedBids?: any;
      } = {};
      if (bidsByVendorResponse?.data?.allBidsByVendor) {
        modifiedResponse = bidsByVendorResponse?.data?.allBidsByVendor;
      }
      return { data: modifiedResponse ?? {}, errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getAllJobs(
    vendorId: string,
    sortBy: string,
    sortOrder: string,
    pageNumber: number,
    limit: number,
    dueDate?: string
  ) {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: allJobs,
          variables: { vendorId, sortBy, sortOrder, pageNumber, limit, dueDate },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      const metadata = response.data?.allJobs?.matchedJobs?.metadata;
      let modifiedResponse: {
        matchedFacilities?: any;
        matchedJobs?: any;
      } = {};

      if (response.data?.allJobs) {
        modifiedResponse = response.data?.allJobs;
        modifiedResponse.matchedJobs = response.data?.allJobs?.matchedJobs?.data;
      }

      return {
        data: modifiedResponse ?? {},
        metadata: metadata,
        errors: [],
      };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getNotInterestedJobs(customerId: string, vendorId: string, sortBy?: string, sortOrder?: string) {
    try {
      const allJobInterestsResponse: IGraphQLResponse = await API.graphql(
        {
          query: jobInterests,
          variables: { customerId, vendorId, sortBy, sortOrder },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: allJobInterestsResponse.data?.jobInterests ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async submittedBids(vendorId: string, pageNumber?: number, limit?: number) {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: bidSubmissionsByVendorId,
          variables: { vendorId, pageNumber, limit },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );

      const modifiedResponse: any = {};
      if (response.data?.bidSubmissionsByVendorId) {
        modifiedResponse.matchedJobsMetaData = response.data?.bidSubmissionsByVendorId?.matchedJobs?.metadata;
        modifiedResponse.matchedJobs = response.data?.bidSubmissionsByVendorId?.matchedJobs?.data;
        modifiedResponse.matchedBids = response.data?.bidSubmissionsByVendorId?.matchedBids?.data;
        modifiedResponse.matchedBidsMetaData = response.data?.bidSubmissionsByVendorId?.matchedBids?.metadata;
        modifiedResponse.matchedFacilities = response.data?.bidSubmissionsByVendorId?.matchedFacilities;
      }
      return { data: modifiedResponse ?? {}, errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }
}
