/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { Roles } from '../../API';

export const BidOverdueStatus = 'Bid overdue';

export enum UserType {
  Associate = 'Associate',
  Customer = 'Customer',
  Vendor = 'Vendor',
  SubVendor = 'Sub vendor',
  PrimeVendor = 'Prime Vendor',
  NoUser = '',
}

export enum Role {
  Supervisor = 'Supervisor',
  Admin = 'Admin',
  Associate = 'Associate/Technician',
  AccountHolder = 'AccountHolder',
  RegionalManager = 'RegionalManager',
  DistrictManager = 'DistrictManager',
  Customer = 'Customer',
  OTJAdmin = 'OTJAdmin',
}

export enum S3BucketLevel {
  private = 'private',
  public = 'public',
}

export enum AWSAuthMode {
  API_KEY = 'API_KEY',
  COGNITO = 'AMAZON_COGNITO_USER_POOLS',
}

export enum ChipStatus {
  'Facilities' = 'homePage:facilities',
  'No Show' = 'homePage:noShow',
  'Incomplete' = 'Incomplete',
  'In progress' = 'homePage:inProgress',
  'Complete' = 'homePage:complete',
  'Pending Reviews' = 'homePage:pendingReviews',
  'Approved' = 'homePage:approved',
  'NoJob' = 'homePage:noJob',
}

export enum StatusMap {
  noShow = 'No Show',
  inComplete = 'Incomplete',
  inProgress = 'In progress',
  complete = 'Complete',
  pendingReviews = 'Pending Reviews',
  approved = 'Approved',
}

export enum MasterData {
  AdditionalExpenses = 'OTJ_ADDITIONAL_EXPENSES',
  FacilityTypes = 'FACILITY_TYPE',
  PreShiftCheckList = 'PRESHIFT_CHECKLIST',
  BID_DUE_DAYS = 'BID_DUE_DAYS',
}

export const PEAZY_PLATFORM_CHARGE = 'PEAZY_PLATFORM_PRICE';

export enum CurrencySymbol {
  Dollar = '$',
}

export enum JobStatus {
  PendingCustomerApproval = 'Pending Customer Approval',
  Awarded = 'Awarded',
  PendingEstimation = 'Pending Estimation',
  Draft = 'Draft',
  RevisionRequested = 'Customer Rejected',
  OpenForBids = 'Open for bids',
  ClosedForBid = BidOverdueStatus,
  OpenToBid = 'OpenToBid',
  InProgress = 'InProgress',
}

export enum TransactionStatus {
  PendingApproval = 'PendingApproval',
  Approved = 'Approved',
  Rejected = 'Rejected',
  ContinueQuoteUpdate = 'Continue quote update',
  PendingApprovalFromCustomer = 'Pending approval from customer',
  CustomerRejectedTheBid = 'Customer rejected the bid',
}

export enum JobStatuses {
  Draft = 'Draft',
  Awarded = 'Awarded',
  OpenToBid = 'OpenToBid',
  Completed = 'Completed',
  Incomplete = 'Incomplete',
  InProgress = 'InProgress',
  Closed = 'Closed',
  Expired = 'Expired',
  Rejected = 'Rejected',
  PendingEstimation = 'Pending Estimation',
  PendingCustomerApproval = 'Pending Customer Approval',
  ClosedForBid = BidOverdueStatus,
  Created = 'Created',
  Upcoming = 'Upcoming',
}

export interface DataStatus {
  InProgress: string;
  Accepted: string;
  Expired: string;
  InComplete: string;
  Incomplete: string;
  OpenToBid: string;
  Draft: string;
  Completed: string;
  ClosedForBid: string;
  Awarded: string;
  Created: string;
}

export const DATA_STATUS: DataStatus = {
  InProgress: 'In-Progress',
  Accepted: 'Accepted',
  Expired: 'Expired',
  InComplete: 'In-Complete',
  Incomplete: 'In-complete',
  OpenToBid: 'Open To bids',
  Draft: 'Draft',
  Completed: 'Completed',
  ClosedForBid: BidOverdueStatus,
  Awarded: 'Awarded',
  Created: 'Awarded',
};

export const DocumentType = {
  PDF: 'application/pdf',
  Image: 'image',
  Video: 'video',
  Document: 'document',
};

export enum FileContextType {
  CustomerApproval = 'CustomerApproval',
  Invoice = 'Invoice',
  JobAttachment = 'JobAttachment',
  VendorQuote = 'VendorQuote',
}

export const numberOfTasksPerBatch = 8;

export enum ReworkShiftType {
  CurrentShift = 'CurrentShift',
  NextWorkDay = 'NextWorkDay',
  SpecificDay = 'SpecificDay',
}

export const enum MediaType {
  Photo = 'photo',
  Video = 'video',
}

export const enum MediaDirection {
  Previous = 'previous',
  Next = 'next',
}

export const enum SortByType {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface IProofsProps {
  comment?: string | null;
  fileExtension?: string;
  fileName?: string;
  id?: string | null;
  mediaType: string;
  url?: string;
  mdAttachmentType?: string;
}

export interface IUser {
  dataStatus?: string;
  email?: string;
  organizationId?: string;
  phoneNumber?: string;
  roles?: Array<Roles>;
  signedUp?: boolean;
  teamId?: string;
  userId?: string;
  username?: string;
}
export enum DraftStatus {
  C = 'C',
  Y = 'Y',
}

export enum DraftStep {
  SelectFacility = 'SelectFacility',
  AddServices = 'AddServices',
  ServicesDetails = 'ServicesDetails',
  FacilitySpecifics = 'FacilitySpecifics',
  Emergency = 'Emergency',
  JobSummary = 'JobSummary',
  Frequency = 'Frequency',
  Vendors = 'Vendors',
  DurationAndDetails = 'DurationAndDetails',
  SearchForVendor = 'SearchForVendor',
  ServicePhotosAndVideos = 'ServicePhotosAndVideos',
  SelectCustomer = 'SelectCustomer',
  JobExecution = 'JobExecution',
}
export interface Job {
  jobId: string;
  jobName: string;
  startDate?: string | null;
  stopDate?: string | null;
  dataStatus?: string | null;
  specialInstructions?: string | null;
  draftStep?: DraftStep | null;
  draftStatus?: DraftStatus | null;
  jobType?: JobType | null;
  bidCount?: number | null;
  createdBy?: string | null;
  createdByName?: string | null;
  jobDueDates?: string;
}

export enum JobType {
  OneTime = 'OneTime',
  Emergency = 'Emergency',
  Recurring = 'Recurring',
}

export enum AppDefaults {
  BidName = 'Untitled',
  Complete = 'Complete',
  Interested = 'Interested',
  NotInterested = 'NotInterested',
}

export enum ApproverType {
  Vendor = 'Vendor',
  Customer = 'Customer',
  PrimeVendor = 'PrimeVendor',
}

export enum Environment {
  Dev = 'dev',
  QA = 'qa',
  Staging = 'staging',
  Prod = 'prod',
  Int = 'int',
  PreProd = 'pre-prod',
  Sandbox = 'sandbox',
}

export enum YearRange {
  Start = 2023,
  End = 2040,
}

export const XMLHttpRequestResponseType = {
  arraybuffer: 'arraybuffer',
  blob: 'blob',
  document: 'document',
  json: 'json',
  text: 'text',
};

export interface States {
  [abbreviation: string]: string;
}

export const states: States = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const IPAddressAPI = 'https://api.ipify.org?format=json';

export const NUMBER_OF_ITEMS_PER_PAGE_IN_FACILITY_TYPES = 20;

export enum PromiseAllSettledResponseStatus {
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

export enum TaskStatus {
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Complete = 'Complete',
  Rejected = 'Rejected',
  Rework = 'Rework',
  OnHold = 'OnHold',
  ApprovedBySupervisor = 'ApprovedBySupervisor',
  ApprovedByCustomer = 'ApprovedByCustomer',
  RejectedBySupervisor = 'RejectedBySupervisor',
  RejectedByCustomer = 'RejectedByCustomer',
  PendingCustomerReview = 'PendingCustomerReview',
  ApprovedByPrimeVendor = 'ApprovedByPrimeVendor',
  RejectedByPrimeVendor = 'RejectedByPrimeVendor',
  PendingPrimeVendorApproval = 'PendingPrimeVendorApproval',
  PendingSupervisorApproval = 'PendingSupervisorApproval',
  IssueRaised = 'Issue raised',
}

export enum ProfitMargin {
  Percentage = 1000,
  Dollar = 100000,
}
export enum PricingOptions {
  Unit = 'Unit',
  FlatRate = 'Flat Rate',
  Sqft = 'Sqft',
  Hours = 'Hours',
}

export enum GraphFilterOptions {
  today = 'today',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
}

export enum ByGraphFilterOptions {
  day = 'dayOfTheWeek',
  month = 'month',
}

export enum SubmittedBidStatus {
  PendingForCustomerApproval = 'Pending for customer approval',
  RevisionRequested = 'Revision requested',
  Rejected = 'Rejected',
}

export enum Language {
  English = 'en',
  Spanish = 'es',
}

export enum IJobType {
  Emergency = 'Emergency',
  OneTime = 'OneTime',
  Recurring = 'Recurring',
}

export enum ModifyQuoteStatus {
  ContinueQuoteUpdate = 'Continue quote update',
  PendingApprovalFromCustomer = 'Pending approval from customer',
  CustomerRejectedTheBid = 'Customer rejected the bid',
}

export enum StorageKeys {
  Auth = 'auth',
  customerDetails = 'customerDetails',
}

export enum BidSubmitStatus {
  saveAndExit = 'saveAndExit',
  updateAndSubmit = 'updateAndSubmit',
}

export enum Units {
  sqft = 'sqft',
}

export const CustomHeaders = {
  'X-PZY-Client-Type': 'Web',
};

export const TagNames = {
  RejectOrRequestRevision: 'rejectOrRequestRevision',
  SelectBid: 'selectBid',
};

export const ExecutionType = {
  SubVendor: 'subVendor',
};

export const EstimationType = {
  EstimateWithinPeazy: 'estimateWithinPeazy',
  EstimateOutsidePeazy: 'estimateOutsidePeazy',
};

export enum DateFormat {
  StandardDate = 'MM/DD/YYYY',
  EuropeanDate = 'DD/MM/YYYY',
  ISODate = 'YYYY-MM-DD',
}

export enum Meridiem {
  AM = 'AM',
  PM = 'PM',
}

export enum JobTypes {
  OneTime = 'One time',
  Emergency = 'Emergency',
  Recurring = 'Recurring',
}

export enum PreShiftChecklistTemplateItemActionTypeNew {
  Brought = 'toBeBrought',
  Provided = 'provided',
  PickedUp = 'buyFromBestBuy',
}
