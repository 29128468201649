/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useState } from 'react';
import {
  buttonStyles,
  DetailsHeadingContainer,
  TitleImgWrapper,
  HeadingText,
  PreShiiftImageContainer,
} from './OneTimeJobDetails.Style';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Icons } from '../../../../Shared/Constants/Icons';
import './OneTimeJob.css';
import { Button, CircularProgress, Divider, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import theme from '../../../../Shared/Themes/theme';
import AddItem from './AddItem';
import { AssignmentCardType, getAuthData } from '../../../../Shared/Utilities/utils';
import { UserType } from '../../../../Shared/Constants/App';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import {
  PreShiftChecklistTemplateInput,
  PreShiftChecklistTemplateItemActionType,
  PreShiftChecklistTemplateItemInput,
  PreShiftChecklistTemplateItemQuantityType,
  WorkOrderStatus,
  WorkOrderYesNoType,
} from '../../../../API';
import { isEmpty } from 'lodash';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';

const behalfOfAssociate = false;
const isJobPunchedOut = false;

export interface IEachOptionsProps {
  completedBy?: string;
  floorNumber?: number;
  id: string;
  isProofOfCompletionRequired?: WorkOrderYesNoType;
  isTaskCompleted?: boolean;
  proof?: boolean;
  quantity?: number;
  quantityName?: string;
  status?: string;
  taskId: string;
  taskScheduleId?: string;
  timeTaken?: string;
  title: string;
  workDayId?: string;
  preShiftChecklistTemplateItemId?: string;
}
export interface IDataProps {
  category?: string;
  categoryTitle?: string;
  headerIcon?: any; // SvgProps;
  id?: string;
  options?: IEachOptionsProps[];
  serviceImage?: string;
  totalCompletedTasks?: number;
  totalScheduledTasks?: number;
  totalTasks?: number;
  keyCode?: string;
  value?: string;
  subKey1Value?: string;
  preShiftChecklistTemplateId?: string;
}

interface PreShiftCheckList {
  isOnlySubVendor: boolean;
  isOnlyPrimeVendor: boolean;
  subWorkOrderId: string;
  workOrderId: string;
  onClose: (type: AssignmentCardType) => void;
  isDataLoading: boolean;
  checkListData: IDataProps[];
  setCheckListData: (data: IDataProps[]) => void;
  assignmentType: string;
  shiftDetailId: string;
}

const PreShiftChecklist = ({
  subWorkOrderId,
  onClose,
  isDataLoading,
  checkListData,
  setCheckListData,
  assignmentType,
  shiftDetailId,
}: PreShiftCheckList) => {
  const { divider, buttonText, createJobButton } = buttonStyles;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [addItemCardOpen, setAddItemCardOpen] = useState<boolean>(false);
  const [addItemData, setAddItemData] = useState<any>({});
  const [selectedCatalogue, setSelectedCatalogue] = useState('');
  const [isPrimaryButtonLoading, setIsPrimaryButtonLoading] = useState<boolean>(false);

  const { t } = useTranslation(['assignJob', 'dashboard', 'calendar', 'altTexts']);
  const { role } = getAuthData();
  const isAssociate = (role === UserType.Associate || behalfOfAssociate) && !isJobPunchedOut;
  const isVendor = role === UserType.Vendor && !behalfOfAssociate && !isJobPunchedOut;

  const providedText = t('vendor:provided');
  const updatedPreShiftChecklistText = t('assignJob:successfullyUpdatedPreShiftChecklist');

  const getStatus = useCallback(
    (status: string, type = '') => {
      if (type === 'query') {
        switch (status) {
          case PreShiftChecklistTemplateItemActionType.Brought.toString():
            return t('vendor:toBeBrought');
          case PreShiftChecklistTemplateItemActionType.PickedUp.toString():
            return t('vendor:buyFromBestBuy');
          case PreShiftChecklistTemplateItemActionType.Provided.toString():
            return providedText;
          default:
            return providedText;
        }
      } else {
        switch (status) {
          case t('vendor:toBeBrought'):
            return PreShiftChecklistTemplateItemActionType.Brought;
          case t('vendor:buyFromBestBuy'):
            return PreShiftChecklistTemplateItemActionType.PickedUp;
          case t('vendor:provided'):
            return PreShiftChecklistTemplateItemActionType.Provided;
          default:
            return PreShiftChecklistTemplateItemActionType.Provided;
        }
      }
    },
    [t]
  );

  const getQuantityName = useCallback(
    (quantityName: string, type = '') => {
      if (type === 'query') {
        switch (quantityName) {
          case PreShiftChecklistTemplateItemQuantityType.Unit.toString():
            return t('common:unit');
          case PreShiftChecklistTemplateItemQuantityType.KG.toString():
            return t('common:kg');
          case PreShiftChecklistTemplateItemQuantityType.Litre.toString():
            return t('common:litre');
          case PreShiftChecklistTemplateItemQuantityType.Pair.toString():
            return t('common:pair');
          case PreShiftChecklistTemplateItemQuantityType.Rolls.toString():
            return t('common:rolls');
          default:
            return undefined;
        }
      } else {
        switch (quantityName) {
          case t('common:unit'):
            return PreShiftChecklistTemplateItemQuantityType.Unit;
          case t('common:kg'):
            return PreShiftChecklistTemplateItemQuantityType.KG;
          case t('common:litre'):
            return PreShiftChecklistTemplateItemQuantityType.Litre;
          case t('common:pair'):
            return PreShiftChecklistTemplateItemQuantityType.Pair;
          case t('common:rolls'):
            return PreShiftChecklistTemplateItemQuantityType.Rolls;
          default:
            return undefined;
        }
      }
    },
    [t]
  );

  const handleAddItemButton = () => {
    setAddItemCardOpen(true);
  };

  const handleAddItemClose = () => {
    setAddItemCardOpen(false);
    setAddItemData({});
  };

  const handleAddItem = (addedItem: any, isEdit: boolean) => {
    if (!isEdit) {
      let alreadyExist = false;
      const updatedChecklistData = checkListData.map((catalogue: any) => {
        const tempCatalogue = { ...catalogue };
        if (catalogue.id === selectedCatalogue) {
          const tempOptions = [];
          catalogue.options?.forEach((item: any) => {
            if (item?.title?.toLowerCase() === addedItem?.title?.toLowerCase()) {
              alreadyExist = true;
              tempOptions.push(addedItem);
            } else {
              tempOptions.push(item);
            }
          });
          if (!alreadyExist) {
            tempOptions.push(addedItem);
          }
          tempCatalogue.options = tempOptions;
          return tempCatalogue;
        }
        return tempCatalogue;
      });
      setCheckListData(updatedChecklistData);
    } else {
      const updatedChecklistData = checkListData.map((catalogue: any) => {
        const tempCatalogue = { ...catalogue };
        if (catalogue.id === selectedCatalogue) {
          const tempOptions: any[] = [];
          catalogue?.options?.forEach((item: any) => {
            if (item.id === addedItem.id) {
              return tempOptions.push({ ...addedItem });
            }

            return tempOptions.push(item);
          });
          tempCatalogue.options = tempOptions;
          return tempCatalogue;
        }

        return tempCatalogue;
      });
      setCheckListData(updatedChecklistData);
    }
    handleAddItemClose();
  };

  const handleAddItemEdit = (catalogueId: string, item: any) => {
    setSelectedCatalogue(catalogueId || '');
    setAddItemData(item);
    setAddItemCardOpen(true);
  };

  const renderAddedItem = (catalogueId: string, items: any[]) => {
    return (
      <>
        {items.map((item: any, index: number) => {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  borderRadius: '1em',
                  padding: '1em',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '1em 0',
                }}
                key={index}>
                <div style={{ maxWidth: '20em', width: '20em' }}>
                  {item.title} - {item.quantity} {item.quantityName}
                </div>
                <div style={{ backgroundColor: theme.palette.error.light, padding: '5px', borderRadius: '5px' }}>
                  {t(`assignJob:${item.status}`)}
                </div>
                <div onClick={() => handleAddItemEdit(catalogueId, item)}>
                  <img src={Icons.EditBlackIcon} alt="Edit" />
                </div>
              </div>
              <Divider sx={divider} />
            </>
          );
        })}
      </>
    );
  };

  const disableSaveChecklistButton = useCallback(() => {
    return checkListData.some((checklistItem) => checklistItem.options?.length) && !isDataLoading ? false : true;
  }, [checkListData]);

  const handleAddItemDelete = (itemForDeletion: any) => {
    const updatedAfterDeletion = checkListData.map((catalogue: any) => {
      const tempOptions = { ...catalogue };
      if (catalogue.id === selectedCatalogue) {
        tempOptions.options = catalogue.options.filter((item: any) => {
          return !(item.id === itemForDeletion.id);
        });
        return tempOptions;
      }
      return catalogue;
    });
    setCheckListData(updatedAfterDeletion);
    setSelectedCatalogue('');
  };

  const savePreShiftChecklist = async () => {
    const updateChecklistData =
      checkListData
        ?.filter((checkList) => !isEmpty(checkList?.preShiftChecklistTemplateId))
        ?.map((item) => {
          const items: PreShiftChecklistTemplateItemInput[] = [];
          item.options?.forEach((optionItem) => {
            const serviceTask: any = {
              itemName: optionItem.title,
              mdItemActionType: getStatus(optionItem.status as string),
              quantity: optionItem.quantity,
              mdQuantityType: getQuantityName(optionItem.quantityName as string),
            };
            if (!isEmpty(optionItem?.preShiftChecklistTemplateItemId)) {
              serviceTask.preShiftChecklistTemplateItemId = optionItem?.preShiftChecklistTemplateItemId;
            }
            items.push(serviceTask);
          });
          return {
            checkListName: item.categoryTitle,
            checkListIconUrl: item.headerIcon,
            mdPreShiftCheckListCategoryId: item.id,
            buildingAccessConfirmation: WorkOrderYesNoType.Yes,
            securityAccessConfirmation: WorkOrderYesNoType.Yes,
            safetyConfirmation: WorkOrderYesNoType.Yes,
            msdsChemicalSafety: WorkOrderYesNoType.Yes,
            workStatus: WorkOrderStatus.Created,
            preShiftChecklistTemplateItems: items?.length ? items : [],
            preShiftChecklistTemplateId: item?.preShiftChecklistTemplateId,
          };
        }) || [];

    const addNewChecklistData =
      checkListData
        ?.filter((checkList) => isEmpty(checkList?.preShiftChecklistTemplateId || ''))
        ?.map((item) => {
          const items: PreShiftChecklistTemplateItemInput[] = [];
          item.options?.forEach((optionItem) => {
            items.push({
              itemName: optionItem.title,
              mdItemActionType: getStatus(optionItem.status as string) as PreShiftChecklistTemplateItemActionType,
              quantity: optionItem.quantity as number,
              mdQuantityType: getQuantityName(
                optionItem.quantityName as string
              ) as PreShiftChecklistTemplateItemQuantityType,
            });
          });
          return {
            checkListName: item.categoryTitle,
            checkListIconUrl: item.headerIcon,
            mdPreShiftCheckListCategoryId: item.id,
            buildingAccessConfirmation: WorkOrderYesNoType.Yes,
            securityAccessConfirmation: WorkOrderYesNoType.Yes,
            safetyConfirmation: WorkOrderYesNoType.Yes,
            msdsChemicalSafety: WorkOrderYesNoType.Yes,
            workStatus: WorkOrderStatus.Created,
            preShiftChecklistTemplateItems: items?.length ? items : [],
          };
        }) || [];

    try {
      setIsPrimaryButtonLoading(true);
      let isSuccessFullSaved = true;
      if (!isEmpty(addNewChecklistData)) {
        if (subWorkOrderId) {
          await Promise.all(
            addNewChecklistData.map(async (checkListItem) => {
              return await WorkOrdersApiService.createPreShiftChecklistTemplate({
                workOrderId: subWorkOrderId,
                createPreShiftChecklistTemplateInput: checkListItem as PreShiftChecklistTemplateInput,
                woScheduleMasterId: shiftDetailId,
              });
            })
          )
            .then((data) => {
              setSnackbarMessage(updatedPreShiftChecklistText);
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              return data;
            })
            .catch((e) => {
              isSuccessFullSaved = false;
              setSnackbarMessage(e);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            });
        } else {
          await Promise.all(
            addNewChecklistData.map((checkListItem) => {
              return WorkOrdersApiService.createPreShiftChecklistTemplate({
                workOrderId: subWorkOrderId,
                createPreShiftChecklistTemplateInput: checkListItem as PreShiftChecklistTemplateInput,
                woScheduleMasterId: shiftDetailId,
              }).then((data) => data);
            })
          )
            .then((data) => {
              setSnackbarMessage(updatedPreShiftChecklistText);
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              return data;
            })
            .catch((e) => {
              isSuccessFullSaved = false;
              setSnackbarMessage(e);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            });
        }
      }
      if (!isEmpty(updateChecklistData)) {
        await Promise.all(
          updateChecklistData.map((checkListItem) => {
            return WorkOrdersApiService.updatePreShiftChecklistTemplate(
              checkListItem?.preShiftChecklistTemplateId as string,
              checkListItem as PreShiftChecklistTemplateInput
            ).then((data) => data);
          })
        )
          .then((data) => {
            setSnackbarMessage(updatedPreShiftChecklistText);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            return data;
          })
          .catch((e) => {
            isSuccessFullSaved = false;
            setSnackbarMessage(e);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          });
      }
      if (isSuccessFullSaved) {
        onClose(assignmentType as AssignmentCardType);
      }
    } catch (e) {
      console.error('Error', e);
    }
    setIsPrimaryButtonLoading(false);
  };

  const handleSaveChecklist = () => {
    if (isAssociate) {
      // submitChecklist();
    } else if (isVendor) {
      savePreShiftChecklist();
    }
  };

  useEffect(() => {
    if (isEmpty(addItemData)) {
      setSelectedCatalogue(checkListData[0]?.id as string);
    }
  }, [checkListData, addItemData]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '50%' }}>
        <HeadingText>{t('assignJob:PreShiftChecklist')}</HeadingText>
        {checkListData.length > 0 && (
          <Button
            sx={{ ...createJobButton, padding: '1em 2em', borderRadius: '1em' }}
            onClick={() => {
              handleAddItemButton();
            }}>
            <Typography sx={{ ...buttonText, color: theme.palette.common.white, width: '100%' }}>
              <div style={{ display: 'flex', width: '100%' }}>
                <img src={Icons.PlusWhiteIcon} alt={t('altTexts:add')} style={{ width: '100%' }} />
                <span style={{ width: '30em' }}>{t('assignJob:addItem')}</span>
              </div>
            </Typography>
          </Button>
        )}
      </div>
      <Divider sx={divider} />
      {isDataLoading ? (
        <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
      ) : (
        <div style={{ height: '50vh', overflow: 'auto', padding: '1em' }}>
          {checkListData.map((item: any) => {
            return (
              <Accordion className="MuiAccordion-root" expanded={item.options.length > 0 ? true : false} key={item.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={'-content'} id={'-header'}>
                  <TitleImgWrapper>
                    <PreShiiftImageContainer>
                      <img src={item.headerIcon} alt={t('altTexts:facility')} />
                    </PreShiiftImageContainer>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem' }}>
                      <DetailsHeadingContainer style={{ paddingLeft: 0, marginBottom: '0.5rem' }}>
                        <HeadingText>{item.categoryTitle}</HeadingText>
                      </DetailsHeadingContainer>
                    </div>
                  </TitleImgWrapper>
                </AccordionSummary>

                <AccordionDetails className="accordian-summary">
                  {item.options.length > 0 && renderAddedItem(item.id, item.options)}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      )}

      <Button
        disabled={disableSaveChecklistButton()}
        sx={{
          ...createJobButton,
          opacity: disableSaveChecklistButton() ? '0.5' : '1',
          maxWidth: '100%',
          marginTop: '2em',
        }}
        onClick={handleSaveChecklist}>
        {isPrimaryButtonLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
        ) : (
          <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
            {t('assignJob:saveChecklist')}
          </Typography>
        )}
      </Button>
      <AddItem
        addItemOpen={addItemCardOpen}
        handleAddItemCardClose={handleAddItemClose}
        handleAddItem={handleAddItem}
        addItemData={addItemData}
        handleAddItemDelete={handleAddItemDelete}
        checkListData={checkListData}
        selectedCatalogue={selectedCatalogue}
        setSelectedCatalogue={setSelectedCatalogue}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default PreShiftChecklist;
