/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { Box, Divider } from '@mui/material';

import { getAcronymName, getAuthDetails, getStoredCustomerDetails } from '../../../Utilities/utils';
import { LogoWrapper, styles } from './LeftNavBar.styles';
import StarIcon from '@mui/icons-material/Star';
import theme from '../../../Themes/theme';

interface ICompanyProfileProps {
  isSideBarVisible: boolean;
  isShowAcronym: boolean;
  logo: string;
  renderLogo: (logo: string | undefined) => JSX.Element;
  vendorRating: number | null;
}

const CompanyProfile: React.FC<ICompanyProfileProps> = ({
  isSideBarVisible,
  isShowAcronym,
  logo,
  renderLogo,
  vendorRating,
}) => {
  const authDetails = getAuthDetails();
  const { organizationName = '' } = getStoredCustomerDetails() || {};

  const organizationNameStyle: React.CSSProperties = {
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  };

  const ratingStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
  };

  const ratingIconStyle: React.CSSProperties = {
    color: theme.palette.primary.main,
    marginLeft: '4px',
  };

  return (
    <Box className="companyProfileWrapper">
      <Divider
        sx={{
          ...styles.divider,
          marginBottom: '2rem',
        }}
      />
      <Box
        className="companyProfile"
        sx={{
          justifyContent: isSideBarVisible ? 'flex-start' : 'center',
          paddingLeft: isSideBarVisible ? '0.75rem' : 0,
          marginBottom: '1rem',
        }}>
        <LogoWrapper>
          {isShowAcronym ? <Box>{getAcronymName(authDetails.name)}</Box> : <>{renderLogo(logo)}</>}
          {isSideBarVisible ? (
            <span style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>{authDetails.name}</span>
          ) : null}
        </LogoWrapper>
      </Box>
      <Box
        className="companyProfile"
        style={{
          justifyContent: isSideBarVisible ? 'flex-start' : 'center',
          paddingLeft: isSideBarVisible ? '0.75rem' : 0,
        }}>
        <LogoWrapper sx={{ marginBottom: '2rem' }}>
          {isShowAcronym ? <Box>{getAcronymName(organizationName)}</Box> : <>{renderLogo(logo)}</>}
          {isSideBarVisible ? (
            <span style={{ ...organizationNameStyle }}>
              {organizationName}&nbsp;
              {vendorRating && (
                <span style={{ ...ratingStyle }}>
                  ({vendorRating}
                  <StarIcon style={{ ...ratingIconStyle }} />)
                </span>
              )}
            </span>
          ) : null}
        </LogoWrapper>
      </Box>
    </Box>
  );
};

export default CompanyProfile;
