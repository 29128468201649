/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';

import { allJobBidEstimation, jobBidSummary } from '../../../graphql/queries';

import { AWSAuthMode } from '../../../Shared/Services/MasterDataService';
import { DeleteJobBidInput } from '../../../API';
import { deleteJobBids } from '../../../graphql/mutations';
import { CustomHeaders } from '../../../Shared/Constants/App';

export interface IGraphQLResponse {
  data?: any;
  errors?: any;
}

export default class VendorApiService {
  static async allServicesLinkedToBid(jobId: string, bidId: string) {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: allJobBidEstimation,
          variables: { bidId, jobId },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: response.data?.allJobBidEstimation ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getBidSummary(bidId: string) {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: jobBidSummary,
          variables: { bidId },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: response.data?.jobBidSummary ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async deleteJobBids(deleteJobBidsInput: DeleteJobBidInput) {
    try {
      const deleteJobBidsResponse: IGraphQLResponse = await API.graphql(
        {
          query: deleteJobBids,
          variables: { deleteJobBidsInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: deleteJobBidsResponse.data?.deleteJobBids ?? {}, errors: [] };
    } catch (e: any) {
      return { data: {}, errors: e?.errors ?? [] };
    }
  }
}
