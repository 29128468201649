/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';

// import { facilityAddressInput } from '@ark/API';
import { facilityAddressInput } from '../../../API';
import { markJobAsAwarded, updateBid } from '../../../graphql/mutations';
import { AWSAuthMode, IGraphQLResponse } from '../../Facility/Services/FacilityApiService';
import { CustomHeaders } from '../../../Shared/Constants/App';

export default class JobAwardBidApiService {
  static async markJobAsAwarded(jobId: string, bidId: string, facilityAddress: facilityAddressInput) {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: markJobAsAwarded,
          variables: { jobId, bidId, facilityAddress },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );

      return { data: response.data?.markJobAsAwarded ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async requestBidRevision(
    vendorId: string,
    bidId: string,
    updateJobInput: { dataStatus: string; comments: string }
  ) {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: updateBid,
          variables: { vendorId, bidId, updateJobInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );

      return { data: response.data?.updatedJob ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }
}
