/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useMemo } from 'react';
import { MRT_ColumnDef } from 'material-react-table';

import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../Jobs/Components/JobView/JobsView';
import { Box, CircularProgress } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { HeaderContainer, ProfitText } from '../../../Vendor/Components/CreateBid.Style';
import { t } from 'i18next';
import { IAwardedBidDetails } from '../../../../Shared/Models/Bid';
import { HeadingText, bidCardStyles } from './AwardedBidDetails.Style';

const AwardedBidDetails = ({ finalQuote, serviceData, isServicesLoading }: IAwardedBidDetails) => {
  const { headerContainer, finalQuoteWrapper } = bidCardStyles;

  const renderPriceCell = (renderedCellValue: any) => {
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      renderedCellValue = parseFloat(String(renderedCellValue)).toFixed(2);
      return renderedCellValue ? `$ ${String(renderedCellValue)}` : '-';
    }
    return '-';
  };

  const renderCell = (renderedCellValue: React.ReactNode) => {
    // If renderedCellValue is a string or number, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    return '-';
  };

  const customerColumns = useMemo<MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'service',
        header: 'Service Category',
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      },
      {
        accessorKey: 'totalJobEstimation',
        header: 'Amount',
        size: 100,
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => renderPriceCell(renderedCellValue),
      },
    ],
    []
  );

  return (
    <>
      <HeaderContainer sx={headerContainer}>
        <div style={{ width: '100%' }}>
          <HeadingText sx={{ color: theme.palette.text.secondary }}>{t('dashboard:bidDetails')}</HeadingText>

          {isServicesLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
          ) : (
            <DataGrid
              columns={customerColumns}
              data={serviceData}
              enableColumnPinning={true}
              enableRowSelect={false}
              enableEditing={true}
            />
          )}
        </div>
        <Box sx={finalQuoteWrapper}>
          <ProfitText
            sx={{
              fontWeight: theme.typography.fontWeightBold,
            }}>
            {t('vendor:finalQuote')}:
          </ProfitText>
          <ProfitText
            sx={{
              fontWeight: theme.typography.fontWeightBold,
            }}>{`$ ${isNaN(finalQuote) ? 0 : parseFloat(finalQuote).toFixed(2)}`}</ProfitText>
        </Box>
      </HeaderContainer>
    </>
  );
};

export default AwardedBidDetails;
