/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';

import { getUserByCognitoUserId } from '../../graphql/queries';
import { CustomHeaders, AWSAuthMode } from '../Constants/App';
import { IGraphQLResponse } from '../Models';

export class AuthenticationService {
  static async getUserByCognitoUserId(cognitoUserId: string) {
    try {
      const userByCognitoUserIdResponse: IGraphQLResponse = await API.graphql(
        {
          query: getUserByCognitoUserId,
          variables: { cognitoUserId },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: userByCognitoUserIdResponse?.data?.getUserByCognitoUserId ?? {}, errors: [] };
    } catch (e: any) {
      return { data: {}, errors: e?.errors ?? [] };
    }
  }
}
