/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState, useMemo, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import Marker from './Marker';
import GoogleMapChips from './GoogleMapChips';
import ErrorScreen from './ErrorScreen';
import NoDataScreen from './NoDataScreen';
import Loader from '../../../../assets/images/ic_loading.svg';
import { ChipStatus } from '../../../Constants/App';
import { Icons } from '../../../Constants/Icons';

import GoogleMapBottomBarOptions from './GoogleMapBottomBarOptions';
import './index.css';
import { FilterType } from './Marker.Model';
import theme from '../../../Themes/theme';

interface IAddressDetails {
  buildingName: string;
  scheduled: number;
  completed: number;
  inProgress: number;
  noShow: number;
  noJob: number;
  address: string;
}

interface IServiceCategory {
  serviceCategory: string;
  serviceCategoryName: string;
  totalTasks: number;
  inProgress: number;
  completed: number;
  approvedBySupervisor: number;
  approvedByCustomer: number;
  rejectedByCustomer: number;
}

interface IGoogleMapProps {
  loading: boolean;
  error: boolean;
  allData: any;
  showNoDataScreen: boolean;
  fetchData: () => void;
  locationData: any;
}

interface IFacilityItem {
  facility: string;
  facilityName: string;
  buildingName?: string;
  latitude: number;
  longitude: number;
  logoUrl: string;
  logourl: string;
  addressLine1: string;
  addressLine2: string;
  facilityStatus: string;
  serviceCategory: IServiceCategory[];
  scheduled: [];
  completed: [];
  inProgress: [];
  noShow: [];
  noJob: [];
  facilityId: string;
}

function createMapOptions(maps: any) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  // const { ControlPosition, ZoomControlStyle } = maps;

  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL,
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: true,
  };
}

type WorkOrder = { workOrderId?: string; jobId?: string; customerId?: string };

const GoogleMap = ({ loading, error, allData, showNoDataScreen, fetchData, locationData }: IGoogleMapProps) => {
  const { lat = 0, long = 0 } = locationData || {};
  const { t } = useTranslation(['homePage', 'altTexts']);
  const [markers, setMarkers] = useState<any[]>([]);
  const defaultProps = {
    center: {
      lat: lat,
      lng: long,
    },
    zoom: 5,
  };

  const [selectedChip, setSelectedChip] = useState(FilterType.All);
  const [selectedDate, setSelectedDate] = useState('');

  const chipsData = useMemo(
    () => [
      {
        label: t(ChipStatus['Facilities']),
        variant: 'outlined',
        count: allData.totalFacilities,
        borderColor: theme.palette.warning.main,
        textColor: theme.palette.text.primary,
        hoverColor: theme.palette.secondary.dark,
        clickColor: theme.palette.secondary.dark,
        type: FilterType.All,
        icon: <img src={Icons.FacilityIcon} alt={t('altTexts:scheduled')} style={{ width: '1rem', height: '1rem' }} />,
      },
      {
        label: 'Job scheduled',
        variant: 'outlined',
        count: allData.scheduled,
        borderColor: theme.palette.info.dark,
        textColor: theme.palette.text.primary,
        hoverColor: theme.palette.secondary.customColor8,
        clickColor: theme.palette.secondary.customColor8,
        type: FilterType.Scheduled,
        icon: <img src={Icons.PendingReviews} alt={t('altTexts:scheduled')} />,
      },
      {
        label: t(ChipStatus['No Show']),
        variant: 'outlined',
        count: allData.noShows,
        borderColor: theme.palette.error.main,
        textColor: theme.palette.text.primary,
        hoverColor: theme.palette.error.light,
        clickColor: theme.palette.error.light,
        type: FilterType.NoShow,
        icon: <img src={Icons.NoShowIcon} alt={t('altTexts:noShow')} />,
      },
      {
        label: t(ChipStatus['In progress']),
        variant: 'outlined',
        count: allData.inProgress,
        borderColor: theme.palette.secondary.contrastText,
        textColor: theme.palette.text.primary,
        hoverColor: theme.palette.secondary.main,
        clickColor: theme.palette.secondary.main,
        type: FilterType.InProgress,
        icon: <img src={Icons.InProgressIcon} alt={t('altTexts:inprogress')} />,
      },
      {
        label: t(ChipStatus['Complete']),
        variant: 'outlined',
        count: allData.completed,
        borderColor: theme.palette.success.main,
        textColor: theme.palette.text.primary,
        hoverColor: theme.palette.success.light,
        clickColor: theme.palette.success.light,
        type: FilterType.Completed,
        icon: <img src={Icons.ApprovedIcon} alt={t('altTexts:complete')} />,
      },
      {
        label: t(ChipStatus['NoJob']),
        variant: 'outlined',
        count: allData?.facilitiesWithoutShifts?.length,
        borderColor: theme.palette.warning.dark,
        textColor: theme.palette.text.primary,
        hoverColor: theme.palette.warning.light,
        clickColor: theme.palette.warning.light,
        type: FilterType.NoJob,
        icon: <img src={Icons.InCompleteIcon} alt={t('altTexts:noJob')} />,
      },
    ],
    [allData, t]
  );

  const renderMapOrNoData = () => (
    <div
      className="reactMap"
      style={{
        height: '43.75rem',
        backgroundColor: theme.palette.warning.contrastText,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        borderRadius: '1.25rem',
      }}>
      {showNoDataScreen ? <NoDataScreen /> : null}
      {!showNoDataScreen && selectedChip ? renderMap() : null}
    </div>
  );

  const countNoJobFacilities = (facilities: IFacilityItem[]): Record<string, number> => {
    return facilities.reduce(
      (acc, item) => {
        if (item.facilityStatus === 'noJob') {
          if (acc[item.facilityId]) {
            acc[item.facilityId] += 1;
          } else {
            acc[item.facilityId] = 1;
          }
        }
        return acc;
      },
      {} as Record<string, number>
    );
  };

  const getFilterType = (item: IFacilityItem): FilterType => {
    if (item.inProgress?.length > 0) return FilterType.InProgress;
    if (item.completed?.length > 0) return FilterType.Completed;
    if (item.noShow?.length > 0) return FilterType.NoShow;
    if (item.scheduled?.length > 0) return FilterType.Scheduled;
    return FilterType.NoJob;
  };

  const getWorkOrderId = (lists: WorkOrder[][]): string | null => {
    for (const list of lists) {
      if (list.length > 0) {
        return list[0]?.workOrderId ?? null;
      }
    }
    return null;
  };

  const getJobId = (lists: WorkOrder[][]): string | null => {
    for (const list of lists) {
      if (list.length > 0) {
        return list[0]?.jobId ?? null;
      }
    }
    return null;
  };

  const getCustomerId = (lists: WorkOrder[][]): string | null => {
    for (const list of lists) {
      if (list.length > 0) {
        return list[0]?.customerId ?? null;
      }
    }
    return null;
  };

  const constructAddressDetails = (item: IFacilityItem, noJobCounts: Record<string, number>): IAddressDetails => {
    const {
      facilityName = '',
      scheduled = [],
      completed = [],
      inProgress = [],
      noShow = [],
      addressLine1 = '',
      addressLine2 = '',
      facility = '',
    } = item || {};

    return {
      buildingName: facilityName,
      scheduled: scheduled?.length,
      completed: completed?.length,
      inProgress: inProgress?.length,
      noShow: noShow?.length,
      noJob: noJobCounts[facility] || 0,
      address: `${addressLine1} ${addressLine2}`,
    };
  };

  const constructFacilityIcon = (logoUrl: string, logourl: string): string => {
    const photoReference = logoUrl || logourl;
    if (photoReference) {
      return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${photoReference}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}`;
    }
    return '';
  };

  const renderMarkers = (type?: FilterType) => {
    const markerData = Object.keys(allData).length ? [...allData.facilityData, ...allData.facilitiesWithoutShifts] : [];
    const noJobCounts = countNoJobFacilities(markerData);
    return markerData?.map((item: IFacilityItem) => {
      const {
        facility = '',
        latitude,
        longitude,
        logoUrl = '',
        logourl = '',
        scheduled = [],
        completed = [],
        inProgress = [],
        noShow = [],
      } = item || {};
      const filterType = getFilterType(item);
      const workOrderId = getWorkOrderId([completed, scheduled, inProgress, noShow]);
      const jobId = getJobId([completed, scheduled, inProgress, noShow]);
      const customerId = getCustomerId([completed, scheduled, inProgress, noShow]);
      const addressDetails = constructAddressDetails(item, noJobCounts);
      const facilityIcon = constructFacilityIcon(logoUrl, logourl);
      if (
        latitude &&
        longitude &&
        typeof latitude === 'number' &&
        typeof longitude === 'number' &&
        (type === undefined || type === FilterType.All || type === filterType)
      ) {
        return (
          <Marker
            key={uuidv4()}
            facilityId={facility}
            logoUrl={facilityIcon}
            data={item?.serviceCategory}
            lng={longitude}
            lat={latitude}
            type={filterType}
            chipType={type}
            addressDetails={addressDetails}
            selectedDate={selectedDate}
            workOrderId={workOrderId}
            jobId={jobId}
            customerId={customerId}
          />
        );
      }
      return null;
    });
  };

  const renderMap = () => {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string }}
        defaultCenter={{
          lat: defaultProps.center.lat,
          lng: defaultProps.center.lng,
        }}
        center={{
          lat: markers.length && markers[0].latitude ? markers[0].latitude : defaultProps.center.lat,
          lng: markers.length && markers[0].longitude ? markers[0].longitude : defaultProps.center.lng,
        }}
        defaultZoom={defaultProps.zoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals>
        {renderMarkers(selectedChip)}
      </GoogleMapReact>
    );
  };

  const applyFilter = (type: FilterType) => {
    setSelectedChip(type);
  };

  useEffect(() => {
    if (allData) {
      const markerData = Object.keys(allData).length
        ? [...allData.facilityData, ...allData.facilitiesWithoutShifts]
        : [];

      const markerPositions = markerData?.map((item: IFacilityItem) => {
        const { latitude, longitude } = item || {};

        return {
          latitude,
          longitude,
        };
      });
      setMarkers(markerPositions ?? []);
    }
  }, [selectedChip, allData]);

  return (
    <div style={{ width: '100%' }}>
      <GoogleMapChips
        loading={loading}
        error={error}
        chipsData={chipsData}
        selectedChip={selectedChip}
        applyFilter={applyFilter}
        fetchData={fetchData}
        setSelectedDate={setSelectedDate}
      />
      <div
        style={{
          height: '43.75rem',
          backgroundColor: theme.palette.warning.contrastText,
          width: '95%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
          borderRadius: '1.25rem',
        }}>
        {loading ? (
          <img src={Loader} alt={t('altTexts:loading')} />
        ) : error ? (
          <ErrorScreen fetchData={fetchData} />
        ) : (
          renderMapOrNoData()
        )}
      </div>
      <GoogleMapBottomBarOptions loading={loading} error={error} allData={allData} />
    </div>
  );
};

export default GoogleMap;
