/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';
import { vendor } from '../../../graphql/queries';
import { AWSAuthMode, IGraphQLResponse } from '../../Facility/Services/FacilityApiService';
import { CustomHeaders } from '../../../Shared/Constants/App';

export default class JobScheduleServices {
  static async vendorDetails(id: string) {
    try {
      const vendorDetailsResponse: IGraphQLResponse = await API.graphql(
        {
          query: vendor,
          variables: { id },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: vendorDetailsResponse?.data?.vendor ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}
