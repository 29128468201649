/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API, Auth } from 'aws-amplify';

import {
  userExist,
  customers,
  getOrgDetailsByUserName,
  serviceCategoryV3,
  getUserScopes,
  masterData,
  masterDataCategory,
} from '../../graphql/queries';
import { serviceCategoryMock } from '../../Modules/Jobs/Components/IssuesRaised/MockData';
import { isEmpty } from 'lodash';
import { Code } from '../../API';
import { CustomHeaders } from '../Constants/App';

export enum AWSAuthMode {
  API_KEY = 'API_KEY',
  COGNITO = 'AMAZON_COGNITO_USER_POOLS',
}

export interface IGraphQLResponse {
  data?: any;
  errors?: any;
}

export default class MasterDataApiService {
  static async getCurrentUserInfo() {
    try {
      return await Auth.currentUserInfo();
    } catch (error) {
      console.error(`CurrentUserInfo: Error occurred while fetching user info ${JSON.stringify(error)}`);
    }
    return null;
  }

  static async checkIfUserExists(userId: string) {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: userExist,
          variables: { user: userId },
          authMode: AWSAuthMode.API_KEY,
        },
        CustomHeaders
      );
      return { data: response.data?.userExist ?? false, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getAllServiceCategoryV2(isMocked = false) {
    if (!isMocked) {
      try {
        const response: IGraphQLResponse = await API.graphql(
          {
            query: serviceCategoryV3,
            variables: { limit: 500, pageNumber: 1 },
            authMode: AWSAuthMode.API_KEY,
          },
          CustomHeaders
        );
        return { data: response.data?.serviceCategoryV3?.data ?? [], errors: [] };
      } catch (e: any) {
        return { data: [], errors: e.errors };
      }
    }
    return { data: serviceCategoryMock, errors: [] };
  }

  static async customerInfo() {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: customers,
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: response.data?.customers?.data ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getOrgDetailsByUserName() {
    try {
      const getOrgDetailsByUserNameResponse: IGraphQLResponse = await API.graphql(
        {
          query: getOrgDetailsByUserName,
          variables: {},
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: getOrgDetailsByUserNameResponse?.data?.getOrgDetailsByUserName, error: [] };
    } catch (err) {
      return { data: [], error: err };
    }
  }

  static async getAllServiceCategoryV3(
    orgId?: string,
    limit?: number,
    pageNumber?: number,
    taskId?: string,
    serviceCategoryId?: string,
    isMocked = false
  ) {
    const variables: {
      limit?: number;
      orgId?: string;
      pageNumber?: number;
      serviceCategoryId?: string;
      taskId?: string;
    } = {};
    if (limit) variables.limit = limit;
    if (pageNumber) variables.pageNumber = pageNumber;
    if (orgId) variables.orgId = orgId;
    if (taskId) variables.taskId = taskId;
    if (serviceCategoryId) variables.serviceCategoryId = serviceCategoryId;

    if (!isMocked) {
      try {
        let response: IGraphQLResponse = await API.graphql(
          {
            query: serviceCategoryV3,
            variables,
            authMode: AWSAuthMode.API_KEY,
          },
          CustomHeaders
        );
        if (!isEmpty(orgId) && isEmpty(response.data?.serviceCategoryV3.data)) {
          const variablesWithoutOrgId = { ...variables };
          delete variablesWithoutOrgId.orgId; // If customer/vendor tries to get services for the 1st time.

          response = await API.graphql(
            {
              query: serviceCategoryV3,
              variables: { ...variablesWithoutOrgId },
              authMode: AWSAuthMode.API_KEY,
            },
            CustomHeaders
          );

          response = await API.graphql(
            {
              query: serviceCategoryV3,
              variables: { ...variablesWithoutOrgId, limit: response.data?.serviceCategoryV3?.metadata.totalCount },
              authMode: AWSAuthMode.API_KEY,
            },
            CustomHeaders
          );
        } else {
          response = await API.graphql(
            {
              query: serviceCategoryV3,
              variables: { ...variables, limit: response.data?.serviceCategoryV3?.metadata.totalCount },
              authMode: AWSAuthMode.API_KEY,
            },
            CustomHeaders
          );
        }
        return { data: response.data?.serviceCategoryV3.data ?? [], errors: [] };
      } catch (e: any) {
        return { data: [], errors: e.errors };
      }
    }
    return { data: [], errors: [] };
  }

  static async getUserScopes(userId: string) {
    try {
      const userScopesResponse: IGraphQLResponse = await API.graphql(
        {
          query: getUserScopes,
          variables: { userId },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: userScopesResponse?.data?.getUserScopes, error: [] };
    } catch (err) {
      return { data: [], error: err };
    }
  }

  static async getMasterDataCategories(id: string, keyId?: string) {
    const variables: { id: string; keyId?: string } = { id };
    if (!isEmpty(keyId)) {
      variables.keyId = keyId;
    }
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: masterData,
          variables,
          authMode: AWSAuthMode.API_KEY,
        },
        CustomHeaders
      );
      return {
        data: response.data?.masterData.data ?? [],
        metaData: response.data?.masterData.metadata ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getAllFacilityTypes(limit?: number, pageNumber?: number) {
    try {
      const variables: {
        id: string;
        limit?: number;
        pageNumber?: number;
      } = {
        id: 'FACILITY_TYPE',
      };
      if (limit) {
        variables.limit = limit;
        if (pageNumber) variables.pageNumber = pageNumber;
      }
      const response: IGraphQLResponse = await API.graphql(
        {
          query: masterData,
          variables,
          authMode: AWSAuthMode.API_KEY,
        },
        CustomHeaders
      );
      return {
        data: response.data?.masterData?.data ?? [],
        metaData: response.data?.masterData?.metadata ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getFacilityTypes(code: Code, keyCode: string) {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: masterDataCategory,
          variables: { code, keyCode },
          authMode: AWSAuthMode.API_KEY,
        },
        CustomHeaders
      );
      return { data: response.data?.masterDataCategory ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getAllPricingOptions() {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: masterData,
          variables: { id: 'PRICING_OPTION' },
          authMode: AWSAuthMode.API_KEY,
        },
        CustomHeaders
      );
      return {
        data: response.data?.masterData.data ?? [],
        metaData: response.data?.masterData.metadata ?? {},
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors, metaData: {} };
    }
  }
}
