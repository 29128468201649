/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import { Loader } from '../../../../Modules/Jobs/Components/JobView/JobsView.styles';

export const Wrapper = styled('div')(() => ({
  backgroundColor: 'white',
  borderRadius: '1rem',
  boxShadow: '0 0.625rem 1.25rem 0.375rem #7957001A',
  width: '100%',
}));

export const HeaderWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '1.5rem 1.5rem 0',
}));

export const Title = styled('p')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: '0.009375rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const Options = styled(MenuItem)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.text.secondary,
  padding: '1rem',
  borderBottom: `0.0625rem solid ${theme.palette.divider}`,
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.dark,
    '&:hover': {
      backgroundColor: theme.palette.warning.contrastText,
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.warning.contrastText,
  },
}));

export const SelectInput = styled(InputBase)(({ theme }) => ({
  '& .MuiSelect-select': {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    borderRadius: '0.5rem',
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h5.fontSize,
    '&:focus': {
      borderRadius: '0.5rem',
    },
  },
  '&> div': {
    padding: '0.5625rem 2.5rem 0.5625rem 1rem !important',
  },
}));

export const DropDown = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const GraphLoader = styled(Loader)(() => ({
  height: '3.5rem',
}));

export const TabSelectInput = styled(InputBase)(({ theme }) => ({
  '& .MuiSelect-select': {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    borderRadius: '0.5rem',
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h5.fontSize,
  },
  '&> div': {
    padding: '0.5625rem 2.5rem 0.5625rem 1rem !important',
  },
  [theme.breakpoints.down('laptop')]: {
    '&> div': {
      padding: '0.5625rem 8.875rem 0.5625rem 1rem !important',
      marginBottom: '1.5rem',
    },
  },
}));
