/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DialogProps, Divider, IconButton, InputAdornment, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { AssignmentCardType, formatDate, hours } from '../../../../Shared/Utilities/utils';
import { JobType } from '../../../../API';
import TasksRequireProofs, { IVendorProofOfCompletion } from './TasksRequireProofs';
import PreShiftChecklist, { IDataProps } from './PreShiftCheckList';
import ShowFacilityDetails, { IAccessFacilityData } from './ShowFacilityDetails';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import AssignTeam from './AssignTeam';

import { Icons } from '../../../../Shared/Constants/Icons';

import {
  DateFieldWrapper,
  DatePickerContainer,
  ErrorMsg,
  buttonStyles,
} from '../AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { AssignmentCardWrapper, Details, HeadingText, TitleCard } from './OneTimeJobDetails.Style';
import { CloseIconStyle } from '../../../Vendor/Components/CreateBid.Style';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';

interface ShiftDetails {
  shiftDetailId: string;
  startTime: string;
  endTime: string;
  workOrderId?: string;
  punchInGraceMinutes?: number | null;
  punchOutGraceMinutes?: number | null;
  scheduleStartDate: string;
  scheduleEndDate: string;
}

export interface IAssociateJobConflictData {
  endDate: string;
  endTime: string;
  facilityAddress: string;
  facilityName: string;
  id: string;
  profileImg: string;
  startDate: string;
  startTime: string;
}

export interface ITeamMemberData {
  id: string | null | undefined;
  isCannotMakeJob?: boolean;
  isChecked: boolean;
  isAlreadyChecked?: boolean;
  userId?: string;
  isDisabled?: boolean;
  isDisabledMoreActions?: boolean;
  isJobNotAccepted?: boolean;
  isSupervisor: boolean;
  name: string | null | undefined;
  profileImg: string;
  profileType?: string;
  punchType?: string;
  role: string | null;
  roleId: string | null;
  teamId?: string | undefined;
  associateStartDate?: string | null;
  associateEndDate?: string | null;
  workDayId?: string;
  punchInTime?: string;
  punchOutTime?: string;
}

export interface IWorkOrderTeam {
  cognitoUserId?: string | null;
  isSupervisor?: boolean | null;
  roleId?: string | null;
  teamId: string;
  userId?: string | null;
  vendorId?: string | null;
  workOrderId?: string | null;
}

interface JobAssignmentCard {
  open: boolean;
  onClose: (type: AssignmentCardType) => void;
  assignmentType?: AssignmentCardType;
  jobData: any;
  shiftTimings: ShiftDetails[];
  isLoading: boolean;
  workOrderData: any;
  workOrderId: string;
  subWorkOrderId: string;
  isOnlySubVendor: boolean;
  isOnlyPrimeVendor: boolean;
  hasChild: boolean;
  teamData: ITeamMemberData[];
  setTeamData: (data: ITeamMemberData[]) => void;
  originalTeamData: ITeamMemberData[];
  setOriginalTeamData: (data: ITeamMemberData[]) => void;
  associateTeamData: ITeamMemberData[];
  setAssociateTeamData: (data: ITeamMemberData[]) => void;
  originalAssociateTeamData: ITeamMemberData[];
  setOriginalAssociateTeamData: (data: ITeamMemberData[]) => void;
  checkedSupervisors: any;
  setCheckedSupervisors: (data: any) => void;
  checkedAssociates: any;
  setCheckedAssociates: (data: any) => void;
  isTeamDataLoading: boolean;
  fetchWorkOrderFacilityAccessInfo: (workOrderId: string) => void;
  facilityAccessData: IAccessFacilityData[];
  isFacilityAccessLoading: boolean;
  proofOfServiceAndTaskData: IVendorProofOfCompletion[];
  tasksWithProof: IVendorProofOfCompletion[];
  setProofOfServiceAndTaskData: (data: IVendorProofOfCompletion[]) => void;
  isTaskRequireProofLoading: boolean;
  isPreShiftChecklistLoading: boolean;
  checkListData: IDataProps[];
  setCheckListData: (data: IDataProps[]) => void;
  shiftDetailId: string;
  isAssignSupervisorLoading: boolean;
  isAssignAssociateLoading: boolean;
  renderAssignedSupervisorTeamData: (shiftId: string) => void;
  renderAssignedAssociateTeamData: (shiftId: string) => void;
  getWorkOrderDetails: () => Promise<void>;
  fetchDisableStartJobResult: any;
  fetchScheduleDetails: (workOrderId: string) => Promise<void>;
}

const getTimeFormat = (time: string) => {
  const hour24 = time.split(':');
  const minutes: string = time.split(':')[1];
  const AmOrPm = +hour24[0] >= 12 ? 'PM' : 'AM';
  const hours = +hour24[0] % 12 || 12;
  return `${hours}:${minutes} ${AmOrPm}`;
};

const JobAssignmentCard = ({
  open,
  onClose,
  assignmentType,
  jobData,
  shiftTimings,
  workOrderData,
  workOrderId,
  subWorkOrderId,
  isOnlySubVendor,
  isOnlyPrimeVendor,
  hasChild,
  teamData,
  setTeamData,
  originalTeamData,
  setOriginalTeamData,
  associateTeamData,
  setAssociateTeamData,
  originalAssociateTeamData,
  setOriginalAssociateTeamData,
  checkedSupervisors,
  setCheckedSupervisors,
  checkedAssociates,
  setCheckedAssociates,
  isAssignSupervisorLoading,
  isAssignAssociateLoading,
  fetchWorkOrderFacilityAccessInfo,
  facilityAccessData,
  isFacilityAccessLoading,
  tasksWithProof,
  setProofOfServiceAndTaskData,
  isTaskRequireProofLoading,
  isPreShiftChecklistLoading,
  checkListData,
  setCheckListData,
  shiftDetailId,
  renderAssignedSupervisorTeamData,
  renderAssignedAssociateTeamData,
  getWorkOrderDetails,
  fetchDisableStartJobResult,
  fetchScheduleDetails,
}: JobAssignmentCard) => {
  const { t } = useTranslation(['assignJob', 'altTexts', 'oneTimeJob', 'calendar']);
  const { textField, divider } = buttonStyles;

  const formatDateString = (dateString: any) => {
    if (dateString) {
      return dateString.replace(/Z$/, '');
    }
    return dateString;
  };

  const { jobStartDate = '', jobEndDate = '', actualStartDate, actualEndDate } = workOrderData || {};

  const formattedJobStartDate = formatDateString(jobStartDate);
  const formattedJobEndDate = formatDateString(jobEndDate);
  const formattedActualStartDate = formatDateString(actualStartDate);
  const formattedActualEndDate = formatDateString(actualEndDate);
  const facilityId = workOrderData?.facilities?.[0]?.facilityId;
  const { control, setValue, getValues, watch } = useForm({
    defaultValues: {
      startDate: formattedJobStartDate ?? null,
      endDate: formattedJobEndDate ?? null,
      shiftStartDate: formattedActualStartDate ?? null,
      shiftEndDate: formattedActualEndDate ?? null,
      startTime: (jobData?.jobDetails?.jobFrequencies && jobData?.jobDetails?.jobFrequencies[0]?.fromTime) ?? '',
      endTime: (jobData?.jobDetails?.jobFrequencies && jobData?.jobDetails?.jobFrequencies[0]?.toTime) ?? '',
    },
  });
  const watchFields = watch();
  const [isStartTimeSelectOpen, setStartTimeSelectOpen] = useState(false);
  const [isEndTimeSelectOpen, setEndTimeSelectOpen] = useState(false);
  const [hoursList, setHoursList] = useState<any[]>([]);
  // const isOneTimeJob = jobData.jobDetails.jobCustodian ? true : false;

  useEffect(() => {
    setHoursList(
      hours.map((hour) => {
        return {
          label: getTimeFormat(hour),
          value: hour,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (workOrderData) {
      const { jobStartDate, jobEndDate, actualStartDate, actualEndDate, jobType } = workOrderData;

      const formattedJobStartDate = formatDateString(jobStartDate);
      const formattedJobEndDate = formatDateString(jobEndDate);
      const formattedActualStartDate = formatDateString(actualStartDate);
      const formattedActualEndDate = formatDateString(actualEndDate);

      setValue(FieldNames.startDate, formattedJobStartDate);
      setValue(FieldNames.endDate, formattedJobEndDate);
      setValue(FieldNames.shiftStartDate, formattedActualStartDate);
      setValue(FieldNames.shiftEndDate, formattedActualEndDate);
      if (jobType === JobType.Emergency || jobType === JobType.OneTime) {
        setValue(FieldNames.shiftStartDate, formattedJobStartDate);
        setValue(FieldNames.shiftEndDate, formattedJobEndDate);
      }
    }
  }, [workOrderData]);

  const disableAddShiftTimingButton = () => {
    const { startDate, endDate, shiftStartDate, shiftEndDate, startTime, endTime } = getValues();
    if (workOrderData?.jobType === JobType.Recurring) {
      return startTime && endTime ? false : true;
    }
    return startDate && endDate && shiftStartDate && shiftEndDate && startTime && endTime ? false : true;
  };

  useEffect(() => {
    disableAddShiftTimingButton();
  }, [watchFields]);

  const renderShiftTimingsContent = () => {
    const { shiftStartDate, shiftEndDate } = getValues();
    return (
      <>
        <HeadingText>{t('assignJob:shiftTimings')}</HeadingText>
        <Divider sx={{ ...divider, margin: '1em 0' }} />
        {shiftTimings.length > 0 ? (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1em' }}>
            {shiftTimings.map((shiftTiming: ShiftDetails) => {
              return (
                <TitleCard
                  sx={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between', width: '48%' }}
                  key={shiftTiming.shiftDetailId}>
                  <div>
                    {!(workOrderData?.jobType === JobType.Recurring) && (
                      <>
                        <Details>{t('assignJob:shiftDate')}</Details>
                        <div>
                          {formatDate(shiftStartDate, 'MM/DD/YYYY')} - {formatDate(shiftEndDate, 'MM/DD/YYYY')}
                        </div>
                      </>
                    )}
                    <Details>{t('assignJob:shiftTime')}</Details>
                    <div>
                      {getTimeFormat(shiftTiming.startTime)} - {getTimeFormat(shiftTiming.endTime)}
                    </div>
                  </div>
                </TitleCard>
              );
            })}
          </div>
        ) : (
          <>
            {/* {isOneTimeJob && (
              <>
                <Details>{t('assignJob:dateRangeToCompleteJobWithin')}</Details>
                <DatePickerContainer>
                  <DateSelection
                    requiredText={t('oneTimeJob:startDateFieldReq')}
                    control={control}
                    isExcludePastDate={false}
                    fieldName={FieldNames.startDate}
                    label={t('calendar:startDate')}
                    iconColor="black"
                  />
                  <DateSelection
                    requiredText={t('oneTimeJob:endDateFieldReq')}
                    control={control}
                    isExcludePastDate={false}
                    fieldName={FieldNames.endDate}
                    label={t('calendar:endDate')}
                    iconColor="black"
                  />
                </DatePickerContainer>
              </>
            )} */}
            {!(workOrderData.jobType === JobType.Recurring) && (
              <>
                <Details>{t('assignJob:pleaseProvideJobFulfillmentStartDateAndEndDate')}</Details>
                <DatePickerContainer>
                  <DateSelection
                    requiredText={t('oneTimeJob:startDateFieldReq')}
                    control={control}
                    fieldName={FieldNames.shiftStartDate}
                    label={t('calendar:startDate')}
                    iconColor="black"
                    isExcludePastDate={false}
                    showSpecificRangeDates={{ from: formattedJobStartDate, to: formattedJobEndDate }}
                  />
                  <DateSelection
                    requiredText={t('oneTimeJob:endDateFieldReq')}
                    control={control}
                    fieldName={FieldNames.shiftEndDate}
                    label={t('calendar:endDate')}
                    iconColor="black"
                    isExcludePastDate={false}
                    showSpecificRangeDates={{ from: formattedJobStartDate, to: formattedJobEndDate }}
                  />
                </DatePickerContainer>
              </>
            )}
            <Details>{t('assignJob:whatTimeCanTheAssociateArriveAtTheFacility')}</Details>
            <Box sx={{ width: '100%' }}>
              <DatePickerContainer>
                <Controller
                  control={control}
                  name={FieldNames.startTime}
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                      <DateFieldWrapper>
                        <TextField
                          select
                          sx={textField}
                          label={t('calendar:startTime')}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)}
                                  style={{
                                    padding: 0,
                                  }}>
                                  <img
                                    src={isStartTimeSelectOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                                    alt={isStartTimeSelectOpen ? 'down' : 'up'}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          SelectProps={{
                            open: isStartTimeSelectOpen,
                            IconComponent: () => null,
                            onOpen: () => setStartTimeSelectOpen(true),
                            onClose: () => setStartTimeSelectOpen(false),
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                                },
                              },
                            },
                          }}>
                          {hoursList.map((item) => (
                            <Options key={'startTime' + item.value} value={item.value}>
                              {item.label}
                            </Options>
                          ))}
                        </TextField>

                        <ErrorMsg>{error && error?.message}</ErrorMsg>
                      </DateFieldWrapper>
                    );
                  }}
                />

                <Controller
                  control={control}
                  name={FieldNames.endTime}
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                      <DateFieldWrapper>
                        <TextField
                          select
                          sx={textField}
                          label={t('calendar:endTime')}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setEndTimeSelectOpen(!isEndTimeSelectOpen)}
                                  style={{
                                    padding: 0,
                                  }}>
                                  <img
                                    src={isEndTimeSelectOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                                    alt={isEndTimeSelectOpen ? 'down' : 'up'}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          SelectProps={{
                            open: isEndTimeSelectOpen,
                            IconComponent: () => null,
                            onOpen: () => setEndTimeSelectOpen(true),
                            onClose: () => setEndTimeSelectOpen(false),
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                                },
                              },
                            },
                          }}>
                          {hoursList.map((item) => (
                            <Options key={'endTime' + item.value} value={item.value}>
                              {item.label}
                            </Options>
                          ))}
                        </TextField>

                        <ErrorMsg>{error && error?.message}</ErrorMsg>
                      </DateFieldWrapper>
                    );
                  }}
                />
              </DatePickerContainer>
            </Box>
          </>
        )}
      </>
    );
  };
  console.log('renderShiftTimingsContent', renderShiftTimingsContent);

  const handleClose: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(assignmentType as AssignmentCardType);
  };

  return (
    <AssignmentCardWrapper open={open} onClose={handleClose}>
      <CloseIconStyle onClick={() => onClose(assignmentType as AssignmentCardType)}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      {/* {assignmentType === AssignmentCardType.ShiftTimings && renderShiftTimingsContent()} */}
      {assignmentType === AssignmentCardType.AssignSupervisor && (
        <AssignTeam
          teamData={teamData}
          shiftDetailId={shiftDetailId}
          shiftTimings={shiftTimings}
          setTeamData={setTeamData}
          originalTeamData={originalTeamData}
          setOriginalTeamData={setOriginalTeamData}
          associateTeamData={associateTeamData}
          setAssociateTeamData={setAssociateTeamData}
          originalAssociateTeamData={originalAssociateTeamData}
          setOriginalAssociateTeamData={setOriginalAssociateTeamData}
          checkedSupervisors={checkedSupervisors}
          setCheckedSupervisors={setCheckedSupervisors}
          checkedAssociates={checkedAssociates}
          setCheckedAssociates={setCheckedAssociates}
          isTeamDataLoading={isAssignSupervisorLoading}
          facilityId={facilityId}
          workOrderId={workOrderId}
          onClose={onClose}
          isViewOnly={hasChild}
          assignmentType={assignmentType}
          workOrderData={workOrderData}
          renderAssignedSupervisorTeamData={renderAssignedSupervisorTeamData}
          renderAssignedAssociateTeamData={renderAssignedAssociateTeamData}
          getWorkOrderDetails={getWorkOrderDetails}
          fetchDisableStartJobResult={fetchDisableStartJobResult}
          fetchScheduleDetails={fetchScheduleDetails}
        />
      )}
      {assignmentType === AssignmentCardType.AssignAssociate && (
        <AssignTeam
          teamData={teamData}
          shiftDetailId={shiftDetailId}
          shiftTimings={shiftTimings}
          setTeamData={setTeamData}
          originalTeamData={originalTeamData}
          setOriginalTeamData={setOriginalTeamData}
          associateTeamData={associateTeamData}
          setAssociateTeamData={setAssociateTeamData}
          originalAssociateTeamData={originalAssociateTeamData}
          setOriginalAssociateTeamData={setOriginalAssociateTeamData}
          checkedSupervisors={checkedSupervisors}
          setCheckedSupervisors={setCheckedSupervisors}
          checkedAssociates={checkedAssociates}
          setCheckedAssociates={setCheckedAssociates}
          isTeamDataLoading={isAssignAssociateLoading}
          facilityId={facilityId}
          workOrderId={workOrderId}
          onClose={onClose}
          isViewOnly={hasChild}
          assignmentType={assignmentType}
          workOrderData={workOrderData}
          renderAssignedSupervisorTeamData={renderAssignedSupervisorTeamData}
          renderAssignedAssociateTeamData={renderAssignedAssociateTeamData}
          getWorkOrderDetails={getWorkOrderDetails}
          fetchDisableStartJobResult={fetchDisableStartJobResult}
          fetchScheduleDetails={fetchScheduleDetails}
        />
      )}
      {assignmentType === AssignmentCardType.FacilityAccess && (
        <ShowFacilityDetails
          workOrderId={workOrderId}
          onClose={onClose}
          fetchWorkOrderFacilityAccessInfo={fetchWorkOrderFacilityAccessInfo}
          facilityAccessData={facilityAccessData}
          isLoading={isFacilityAccessLoading}
          assignmentType={assignmentType}
        />
      )}
      {assignmentType === AssignmentCardType.ProofOfCompletionTasks && (
        <TasksRequireProofs
          workOrderId={workOrderId}
          subWorkOrderId={subWorkOrderId}
          isOnlySubVendor={isOnlySubVendor}
          workOrderData={workOrderData}
          onClose={onClose}
          proofOfServiceAndTaskData={tasksWithProof}
          setProofOfServiceAndTaskData={setProofOfServiceAndTaskData}
          isDataLoading={isTaskRequireProofLoading}
          assignmentType={assignmentType}
          fetchScheduleDetails={fetchScheduleDetails}
        />
      )}
      {assignmentType === AssignmentCardType.PreShiftChecklist && (
        <PreShiftChecklist
          isOnlySubVendor={isOnlySubVendor}
          isOnlyPrimeVendor={isOnlyPrimeVendor}
          workOrderId={workOrderId}
          subWorkOrderId={subWorkOrderId}
          onClose={onClose}
          isDataLoading={isPreShiftChecklistLoading}
          checkListData={checkListData}
          setCheckListData={setCheckListData}
          assignmentType={assignmentType}
          shiftDetailId={shiftDetailId}
        />
      )}
    </AssignmentCardWrapper>
  );
};

export default JobAssignmentCard;
