/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { buttonStyles, CardContainer, HeadingText } from './OneTimeJobDetails.Style';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { Loader } from '../JobView/JobsView.styles';
import { AssignmentCardType } from '../../../../Shared/Utilities/utils';
export interface IAccessFacilityData {
  accessInfo: string;
  code: string;
  contactPerson: string;
  contactPersonNumber: string;
  contactPersonType?: string;
  location: string;
  parking: string;
}

interface ShowFacilityDetails {
  workOrderId: string;
  onClose: (type: AssignmentCardType) => void;
  fetchWorkOrderFacilityAccessInfo: (workOrderId: string) => void;
  facilityAccessData: IAccessFacilityData[];
  isLoading: boolean;
  assignmentType: string;
}

const ShowFacilityDetails = ({
  workOrderId,
  onClose,
  fetchWorkOrderFacilityAccessInfo,
  facilityAccessData,
  isLoading,
  assignmentType,
}: ShowFacilityDetails) => {
  const { t } = useTranslation(['oneTimeJob', 'calendar', 'noData', 'altTexts']);
  const { divider } = buttonStyles;
  const { buttonText, createJobButton } = buttonStyles;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const snackbarMessage = '';
  const snackbarSeverity = 'success';

  // const handleContactPerson = (data: string) => {
  //   window.open(`tel:${data}`, '_self');
  // };

  const renderData = () => {
    return (
      <>
        <CardContainer>
          {facilityAccessData.map((data: any) => {
            const contactPersonName = `${data.contactPerson} ${
              data.contactPersonType ? `(${data.contactPersonType})` : ''
            }`;
            // const contactPersonCallName =
            //   data.contactPerson !== t('vendor:noContactPersonFound')
            //     ? `${t('common:call')} ${data.contactPerson}`
            //     : data.contactPerson;
            return (
              <>
                <div className="TitleContainer">Location:</div>
                <div className="contentFlex">
                  <img src={Icons.LocationIcon} />
                  <div className="Name">{data?.location}</div>
                </div>
                <div className="TitleContainer">{data.accessInfo}:</div>
                <div className="contentFlex">
                  <img src={Icons.OtpIcon} />
                  <div className="Name">{data.code}</div>
                </div>
                <div className="TitleContainer">Parking at:</div>
                <div className="contentFlex">
                  <img src={Icons.ParkingIcon} />
                  <div className="Name">{data.parking}</div>
                </div>
                <div className="TitleContainer">Contact person:</div>
                <div className="contentFlex">
                  <img src={Icons.AccountIcon} />
                  <div className="Name">{contactPersonName}</div>
                </div>
              </>
            );
          })}
        </CardContainer>

        <Button
          disabled={isEmpty(facilityAccessData[0].contactPersonNumber)}
          sx={{ ...createJobButton, maxWidth: '100%', marginTop: '2em', width: '100%' }}
          onClick={() => onClose(assignmentType as AssignmentCardType)}>
          {isLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>{t('assignJob:okay')}</Typography>
          )}
        </Button>
      </>
    );
  };

  const NoData = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            padding: 3,
          }}>
          <img src={Icons.ErrorCautionImg} alt={t('altTexts:nodata')} />
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.h3?.fontSize,
                fontWeight: theme.typography.fontWeightBold,
                lineHeight: '1.375rem',
                letterSpacing: '0.009375rem',
              }}>
              {t('noData:noFacilityAccessData')}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  useEffect(() => {
    fetchWorkOrderFacilityAccessInfo(workOrderId);
  }, []);

  return (
    <>
      <HeadingText>{t('assignJob:facilityAccess')}</HeadingText>
      <Divider sx={divider} />
      {isLoading ? (
        <Loader sx={{ marginBottom: '4em' }}>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </Loader>
      ) : facilityAccessData && facilityAccessData.length > 0 ? (
        renderData()
      ) : (
        NoData()
      )}
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};
export default ShowFacilityDetails;
