/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import * as DefaultThemeIcons from './GreenThemeIcons';

export const Icons = DefaultThemeIcons;

export const {
  CautionBordErrorImg,
  CheckedIcon,
  ErrorCautionImg,
  ExteriorCleaningImg,
  ExteriorImg,
  FacadeCleaningImg,
  FacadeImg,
  FurnitureCleaningImg,
  FurnitureImg,
  AddIcon,
  ArrowForwardIcon,
  CalendarIcon,
  DropdownIcon,
  LoadingImg,
  MoreIcon,
  PendingReviewsIcon,
  PhotosIcon,
  SendIcon,
  UploadIcon,
  VideoIcon,
  JanitorialServicesImg,
  JanitorialImg,
  JobIcon,
  BidIcon,
  Calendar,
  CancelIcon,
  ChevronRightIcon,
  ChevronSliderLeftIcon,
  ChevronSliderRightIcon,
  ClockIcon,
  CreateJobIcon,
  DayAndNightIcon,
  DayIcon,
  DownloadBlackIcon,
  DownloadIcon,
  DropdownDataIcon,
  EditBlackIcon,
  EyeIcon,
  AddJobIcon,
  AddTagIcon,
  ApprovedCustomerMap,
  InprogressMapIcon,
  CompleteMapIcon,
  PendingReviewsMapIcon,
  NoShowMapIcon,
  NoShowIcon,
  InCompleteMapIcon,
  CompleteIcon,
  ApprovedIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  CancelSmallIcon,
  ClearIcon,
  ConditionCheckCorrectIcon,
  ConditionCheckIcon,
  DeleteIcon,
  DropdownDownIcon,
  DropdownUpIcon,
  EditBlack,
  EmailIcon,
  PhoneNoIcon,
  FacebookIcon,
  LinkedInIcon,
  AppleIcon,
  GoogleIcon,
  GooglemapIcon,
  FacilityIcon,
  HomeIcon,
  InProgressIcon,
  InCompleteIcon,
  InfoWindowCloseIcon,
  JobsIcon,
  LeftArrowIcon,
  RightChevronIcon,
  LeftChevronIcon,
  LinkIcon,
  LogoutIcon,
  MarketPlaceIcon,
  MicIcon,
  PeazyLogoLogin,
  PlusIcon,
  SearchIcon,
  SettingsIcon,
  SideBarCloseIcon,
  SideBarOpenIcon,
  UploadFilesIcon,
  UserManagementIcon,
  VisibilityOffIcon,
  VisibilityOnIcon,
  VoiceIcon,
  CongratulationsImg,
  LinearGradientLeftImg,
  LinearGradientRightImg,
  LocationIcon,
  CustomerStayFitGymLogo,
  FacilityDetailsLogo,
  StayFitGymLogo,
  VelocitiLogo,
  VendorDetailsLogo,
  VendorLogo,
  PeazyLogo,
  PeazyNameLogo,
  PersonImg,
  FloorCareImg,
  BuildingImg,
  NightIcon,
  PlusWhiteIcon,
  SuccessIcon,
  RejectIcon,
  RevisedBidIcon,
  ShortlistIcon,
  SortingIcon,
  StarRatingFilledIcon,
  TickIcon,
  TicketIcon,
  TrashIcon,
  Unchecked,
  VectorIcon,
  ConversationIcon,
  AddProofIcon,
  CustomerRejectedIcon,
  TaskIcon,
  LocationMarkerIcon,
  WorkCompletedIcon,
  FacilityMapIcon,
} = Icons;
