/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export interface IData {
  name: string;
  amount: number;
  date: string;
}

export interface IDataV2 {
  name: string;
  amount: number;
}

export enum GraphType {
  BarGraph = 'BarGraph',
  LineGraph = 'LineGraph',
}

export interface ITaskParameters {
  Status?: string;
  associateId?: string;
  facilityId?: string;
  fromDate?: string;
  serviceId?: string;
  status?: string;
  toDate?: string;
  workDayId?: string;
  workOrderId?: string;
}
