/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useCallback, useState } from 'react';
import JobCreateService from '../../Services/JobCreateService';
import { useTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ApproveDialog from './DeleteDialog';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import { DeleteType } from '../../../../API';

interface IDeleteJobs {
  isApproveWindowOpen: boolean;
  handleCloseApproveWindow: () => void;
  deleteJobId: string;
  deleteJobName: string;
  fetchJobsView: (type: string, pageNumber: number) => void;
  jobType?: string;
  tabSelected?: number;
  fetchData: () => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const DeleteJobs = (props: IDeleteJobs) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [handleClose, setHandleClose] = useState(false);

  const navigate = useNavigate();

  const DeleteUI = () => {
    const { t } = useTranslation(['delete', 'altTexts']);
    return (
      <div>
        {t('delete:deleteText')} <br /> <strong>{props.deleteJobName}</strong>
      </div>
    );
  };

  const DeleteJobs = useCallback(async (jobId: string | null, jobName: string, jobType?: string) => {
    try {
      setLoading(true);
      const deleteType = DeleteType.Soft;
      const allJobResponse = await JobCreateService.deleteJobs([jobId], deleteType);
      if (allJobResponse?.data) {
        setLoading(false);
        setSnackbarMessage(`${jobName} got deleted successfully`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        setHandleClose(true);
        props.handleCloseApproveWindow();
        if (jobType === 'OneTime') {
          navigate('/jobs-view/onetime');
          props.fetchJobsView(jobType, 1);
        } else if (jobType === 'Recurring') {
          navigate('/jobs-view/recurring');
          props.fetchJobsView(jobType, 1);
        } else if (jobType === '') {
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
        if (props?.tabSelected === 0) {
          navigate('/vendor-jobs/self-performed-jobs');
          props.fetchData();
        } else if (props?.tabSelected === 1) {
          navigate('/vendor-jobs/sub-contractor-jobs');
          props.fetchData();
        }
      } else {
        setLoading(false);
        setSnackbarMessage(`An error occurred while deleting the job. Please try later.`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setLoading(false);
      return [];
    } catch (e) {
      console.error('Error', e);
      setLoading(false);
      return [];
    }
  }, []);

  return (
    <>
      <ApproveDialog
        loading={loading}
        ChildComponent={<DeleteUI />}
        isApproveWindowOpen={props.isApproveWindowOpen}
        showCancelButton={false}
        handleCloseApproveWindow={props.handleCloseApproveWindow || handleClose}
        handleApprove={() => DeleteJobs(props.deleteJobId, props.deleteJobName, props?.jobType)}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default withTranslation(['logout'])(DeleteJobs);
