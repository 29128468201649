/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useState, useEffect, useReducer, useCallback } from 'react';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import GoogleMap from '../../../../Shared/Components/Common/GoogleMap/GoogleMap';
import { GraphTemplateV2 } from '../../../../Shared/Components/Common/GraphContainer/index';
import { GraphType } from '../../../../Shared/Components/Common/Graphs/models';
import { HomePageContainer, SearchFieldContainer, SearchInput } from '../../../../Shared/Components/Layout/styles';
import { INITIALSTATE, postReducer } from '../../../../Shared/Store/reducer/postReduer';
import { ACTION_TYPES } from '../../../../Shared/Store/actions/postActions';
import {
  getStoredCustomerDetails,
  getAuthDetails,
  removeJobLocalData,
  getAuthData,
} from '../../../../Shared/Utilities/utils';
import Button, { ButtonType, IconDirection } from '../../../../Shared/Components/Common/Buttons/Button';
import AnalyticsApiService, { UserType } from '../../../../Shared/Services/AnalyticsService';
import { PermissionGate } from '@abrightlab/client-rbac';
import { Role } from '../../../../Shared/Constants/App';
import theme from '../../../../Shared/Themes/theme';
import { Icons } from '../../../../Shared/Constants/Icons';
import { Auth } from 'aws-amplify';
import ApproveDialog from '../../../Jobs/Components/TaskDetails/logoutDialog';
import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';

const Graphs = {
  OneTimeJob: 'OneTimeJob',
  NoShows: 'NoShows',
};

const HomePage = () => {
  const [showNoDataScreen, setShowNoDataScreen] = useState(false);
  const [state, dispatch] = useReducer(postReducer, INITIALSTATE);
  const [noShowGraphData, setNoShowGraphData] = useState({});
  const [isNoShowsDataLoading, setIsNoShowsDataLoading] = useState(false);
  const [isShowSessionExpirePopUp, setIsShowSessionExpirePopUp] = useState(false);
  const [isShowSessionExpirePopUpLoading, setIsShowSessionExpirePopUpLoading] = useState(false);
  const { loading, error, data } = state;
  const [allData, setAllData] = useState({});
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedGraph, setSelectedGraph] = useState('');
  const { t } = useTranslation(['homePage', 'oneTimeJob']);
  const navigate = useNavigate();

  // fetching no show graph data
  const getNoShowGraphData = async (startDate, endDate) => {
    try {
      setIsNoShowsDataLoading(true);
      const storedAuthDetails = getAuthDetails();
      const { customerId = '', userId } = getStoredCustomerDetails() || {};
      const noShowFacilities = await AnalyticsApiService.noShowFacilities(
        customerId,
        startDate,
        endDate,
        storedAuthDetails['custom:Role'] === 'Customer' ? UserType.Customer : UserType.Vendor,
        userId
      );

      if (noShowFacilities?.data) {
        const modifiedData = noShowFacilities?.data?.map((dataItem) => {
          return {
            name: dataItem.facilityName,
            value: dataItem.count,
          };
        });

        setNoShowGraphData({
          title: 'Missed Jobs',
          graphView: 'vertical',
          graphColor: theme.palette.secondary.customColor3,
          data: modifiedData,
        });
      }
      setIsNoShowsDataLoading(false);
    } catch (e) {
      setIsNoShowsDataLoading(false);
      console.log('e', e);
    }
  };

  const fetchData = async (date) => {
    try {
      dispatch({ type: ACTION_TYPES.FETCH_START });
      const storedAuthDetails = getAuthDetails();
      const { customerId = '', userId } = getStoredCustomerDetails() || {};
      const allIdsResponse = await AnalyticsApiService.taskSummary(
        customerId,
        date,
        storedAuthDetails['custom:Role'] === 'Customer' ? UserType.Customer : UserType.Vendor,
        userId
      );

      if (allIdsResponse?.errors?.length) {
        dispatch({ type: ACTION_TYPES.FETCH_ERROR });
      } else if (typeof allIdsResponse?.data === 'object') {
        if (!Object.keys(allIdsResponse?.data)?.length) {
          dispatch({ type: ACTION_TYPES.FETCH_ERROR, payload: data });
          setShowNoDataScreen(true);
        } else if (Object.keys(allIdsResponse?.data)?.length) {
          setAllData(allIdsResponse?.data);
          dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: data });
        }
      }
    } catch (e) {
      console.log('error in catch', e);
      dispatch({ type: ACTION_TYPES.FETCH_ERROR });
    }
  };

  const onDateRangeSelect = (selectedRange, id) => {
    let startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');
    switch (selectedRange) {
      case '7D':
        startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
        break;
      case '15D':
        startDate = moment().subtract(15, 'days').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
        break;
      case '30D':
        startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
        break;
      default:
        startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
    }
    setFromDate(startDate);
    setToDate(endDate);
    setSelectedGraph(id);
  };

  useEffect(() => {
    getNoShowGraphData(moment().subtract(15, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
  }, []);

  useEffect(() => {
    if (fromDate && toDate && selectedGraph === Graphs.NoShows) {
      getNoShowGraphData(fromDate, toDate);
    }
  }, [fromDate, toDate, selectedGraph]);

  useEffect(() => {
    // const storedAuthDetails = getAuthDetails();
    localStorage.removeItem(AsyncStorageKeys.viewJob);
    localStorage.removeItem(AsyncStorageKeys.currentJob);
    removeJobLocalData();
  }, []);

  const signOut = useCallback(async () => {
    try {
      setIsShowSessionExpirePopUpLoading(true);
      await Auth.signOut();
      localStorage.removeItem('auth');
      localStorage.removeItem('customerDetails');
      navigate('/login');
    } catch (error) {
      console.error('An error occurred during sign out:', error);
    } finally {
      setIsShowSessionExpirePopUpLoading(false);
    }
  }, []);

  const LogoutUI = () => {
    return (
      <div>
        {t('logout:logoutConfirm')} <br /> <strong>{t('logout:peazyApplication')}</strong>
      </div>
    );
  };

  const locationData = { lat: 33.753746, long: -84.38633 };
  const { role, isDistrictManager } = getAuthData();

  return (
    <div>
      {/* <Box
        sx={{
          padding: '16px 240px',
          textAlign: 'center',
          color: 'white',
          fontFamily: 'Manrope',
          backgroundColor: '#006B6B',
        }}>
        <Typography>
          Important Update: Peazy Upgrade Scheduled Thursday, September 19th 8:30am-2pm Eastern. System will be down for
          20 minutes and have reduced functionality during this window of time.
        </Typography>
      </Box> */}
      <HomePageContainer>
        <Box
          sx={{
            display: 'flex',
            padding: '2rem 0rem 1.5rem 0',
            gap: '1.5rem',
            width: '100%',
          }}>
          <SearchFieldContainer>
            <SearchInput type="text" placeholder={t('oneTimeJob:search')} />
            <img src={Icons.SearchIcon} alt={t('altTexts:search')} style={{ width: '1.5rem', height: '1.5rem' }} />
          </SearchFieldContainer>
          <PermissionGate
            roles={[
              Role.AccountHolder,
              Role.Supervisor,
              Role.DistrictManager,
              Role.RegionalManager,
              Role.Customer,
              Role.OTJAdmin,
              Role.Admin,
            ]}
            permissions={['']}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison */}
            {isDistrictManager || role === UserType.Customer ? (
              // <Link to="/createOneTimeJob" style={{ textDecoration: 'none' }}>
              <Button
                onClick={async () => {
                  const authInfo = localStorage.getItem('auth');
                  const { signedInTime } = JSON.parse(authInfo);
                  const isPreviousSignedSessionMoreThan_55_minutes =
                    new Date().getTime() - signedInTime > 1000 * 60 * 55; //checking 55 minutes difference from singed in time.
                  if (isPreviousSignedSessionMoreThan_55_minutes) {
                    setIsShowSessionExpirePopUp(true);
                  } else {
                    localStorage.removeItem(AsyncStorageKeys.currentJob);
                    navigate('/createOneTimeJob');
                  }
                }}
                icon={<img src={Icons.PlusWhiteIcon} alt={t('altTexts:add')} />}
                type={ButtonType.Primary}
                label={t('homePage:oneTimeJob')}
                iconDirection={IconDirection.Right}
              />
            ) : // </Link>
            null}
          </PermissionGate>
        </Box>

        <Box
          sx={{
            boxShadow: '0rem 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10)',
            backgroundColor: 'white',
            overflow: 'hidden',
            borderRadius: 3,
            marginBottom: 3,
          }}>
          <GoogleMap
            allData={allData}
            loading={loading}
            error={error}
            fetchData={fetchData}
            showNoDataScreen={showNoDataScreen}
            locationData={locationData}
          />
        </Box>

        <Box
          sx={{
            marginBottom: 3,
          }}>
          <GraphTemplateV2
            graphData={noShowGraphData}
            id={Graphs.NoShows}
            type={GraphType.BarGraph}
            loading={isNoShowsDataLoading}
            onDateRangeSelect={onDateRangeSelect}
          />
        </Box>

        <ApproveDialog
          title={t('logout:sessionTimedOutTitle')}
          loading={isShowSessionExpirePopUpLoading}
          ChildComponent={<LogoutUI />}
          isApproveWindowOpen={isShowSessionExpirePopUp}
          showCancelButton={false}
          isShowCloseIcon={false}
          handleCloseApproveWindow={() => setIsShowSessionExpirePopUp(false)}
          handleApprove={() => signOut()}
        />
      </HomePageContainer>
    </div>
  );
};

export default HomePage;
