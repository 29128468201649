/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { JobInterestsActionType, WorkOrderStatus } from '../../../API';

export enum JobType {
  Accepted = 'Accepted',
  All = 'All',
  BidsInProgress = 'BidsInProgress',
  Completed = 'Completed',
  Created = 'Created',
  Drafts = 'drafts',
  InProgress = 'InProgress',
  OTJDrafts = 'OTJDrafts',
  OTJOneTimeJobs = 'OTJOneTimeJobs',
  OnHold = 'OnHold',
  OneTimeJobs = 'OneTime',
  RecommendedJob = 'RecommendedJob',
  SubmittedBids = 'submittedBids',
}

export interface ModifiedJobData {
  id?: string;
  bidDueDate?: string | null;
  createdOn: string;
  logo: string;
  address: string;
  facilityName: string;
  type: string;
  jobId: string;
}

export interface JobItem {
  jobId: string;
  jobName: string;
  specialInstructions?: string | null;
  startDate?: string;
  stopDate?: string;
  jobSize?: string;
  dataStatus?: string;
  jobType?: string;
  createdOn?: string;
  percentageMatch?: number;
  actionTime?: string;
  actionType?: JobInterestsActionType;
}

export interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface IJobRecommendationsType {
  data: any;
  metadata?: any;
  errors?: any;
}

export interface IVendorJobAwardData {
  customerCompanyName: string;
  customerFacilityAddress: string;
  customerFacilityName: string;
  customerIcon: string;
  customerId: string;
  facilityId: string;
  hasChild: boolean;
  jobCustodian?: string;
  jobEndDate: string;
  jobName: string;
  jobStartDate: string;
  jobType: string;
  logo: string;
  totalAmount: number;
  vendorCompanyName: string;
  vendorIcon: string;
  vendorId: string;
  workOrderId: string;
  workOrderStatus: WorkOrderStatus;
  workVolume: string;
  jobId: string;
}

export interface IFacilityType {
  icon: React.ReactNode;
  id: string;
  name: string;
  keyCode: string;
}

export interface IFacilityTypeItem {
  keyCode: string;
  value: string;
}
