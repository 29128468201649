/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';

import { resendBidEmail } from '../../../graphql/mutations';
import { ResendBidEmail } from '../../Vendor/Models/OneTimeJob.model';
import { IGraphQLResponse } from './JobCreateService';
import { CustomHeaders } from '../../../Shared/Constants/App';

export default class OneTimeJobApiService {
  static async resendEmail(bidId: string): Promise<{ data: ResendBidEmail | []; errors: [] }> {
    try {
      const resendEmailResponse: IGraphQLResponse = await API.graphql(
        {
          query: resendBidEmail,
          variables: { bidId },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        },
        CustomHeaders
      );
      return { data: resendEmailResponse.data?.resendBidEmail ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}
