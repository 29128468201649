/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const InputWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  '&:first-of-type': {},
}));

export const ErrorMsg = styled('span')(() => ({
  color: theme.palette.error.main,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5?.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  paddingLeft: '0.625rem',
  position: 'absolute',
  bottom: '-1.25rem',
}));
