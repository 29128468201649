/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import i18next, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslations from '../assets/translations/en.json';
import spanishTranslations from '../assets/translations/es.json';

use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: englishTranslations,
    es: spanishTranslations,
  },
  // have a common namespace used around the full app
  ns: ['common'],
  supportedLngs: ['en', 'es'],
  keySeparator: false,
  defaultNS: 'common',
  debug: true,
  cache: {
    enabled: true,
  },
  interpolation: {
    escapeValue: false, // not needed for react as it does escape per default to prevent xss!
  },
});

export default i18next;
