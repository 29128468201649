/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { withTranslation } from 'react-i18next';
import { Typography, Divider, Box } from '@mui/material';
import { buttonStyles } from './TaskDetailsPage.Style';
import theme from '../../../../Shared/Themes/theme';
import { convertToReadableFormat } from '../../../../Shared/Utilities/utils';

const ApproveOrRejectCard = ({ service, address, status, name, timeTaken, photos, videos, associateLogo, t }) => {
  const { nameAndMessage, tasKDetailsCardBody, divider, nameAndMessages, tasKDetailsCardMedia } = buttonStyles;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
      }}>
      <Box sx={{ width: '100%' }}>
        <Typography sx={nameAndMessage} marginBottom={0.5} fontWeight={700}>
          {service}
        </Typography>
        <Typography sx={tasKDetailsCardBody}>{address}</Typography>
      </Box>
      <Divider sx={divider} />

      <Box sx={{ width: '100%' }}>
        <Box sx={{ marginBottom: '0.25rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <Typography sx={nameAndMessage} fontWeight={700}>
            {t('dashboard:status')}:&nbsp;
          </Typography>
          <Typography
            sx={{
              ...nameAndMessages,
              color:
                status === 'ApprovedByCustomer' || status === 'Complete' ? theme.palette.success.main : 'error.main',
            }}
            marginBottom={0.5}
            fontWeight={700}>
            {convertToReadableFormat(status)}
          </Typography>
        </Box>
        {/* <Typography sx={tasKDetailsCardBody}>
          {t('dashboard:taskNo')} : {taskNumber}
        </Typography> */}
      </Box>
      <Divider sx={divider} />

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <Box>
          <Typography sx={nameAndMessage} marginBottom={0.5} fontWeight={700}>
            {name}
          </Typography>
          <Typography sx={tasKDetailsCardBody}>
            {t('dashboard:timeTaken')} : {timeTaken || '-'}
          </Typography>
        </Box>
        <Box sx={{}}>
          <img
            src={associateLogo || IntersectionObserver.PersonImg}
            alt={t('altTexts:image')}
            width={40}
            height={40}
            style={{ borderRadius: '2.5rem' }}
          />
        </Box>
      </Box>

      <Divider sx={divider} />
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <Typography sx={tasKDetailsCardMedia} marginBottom={1}>
            {photos} {t('dashboard:photos')}, {videos} {t('dashboard:videos')}
          </Typography>
          {/* <Typography sx={tasKDetailsCardMedia} marginBottom={1}>
            {audio} audio
          </Typography>
          <Typography sx={tasKDetailsCardMedia} marginBottom={4}>
            {comments} {t('dashboard:comments')}
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation(['dashboard', 'altTexts'])(ApproveOrRejectCard);
