/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const config = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:42fae29a-e8d9-45bf-8394-096529bcaf24',
  aws_user_pools_id: 'us-east-1_mPiL828df',
  aws_user_pools_web_client_id: '4srda6sbkt1mgaj4grfdhd248d',
  oauth: {
    domain: 'abrightlab.auth.us-east-1.amazoncognito.com',
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'],
    redirectSignIn:
      'http://localhost:3000/home,https://www.customer-dev.abrightlab.com/home,https://www.customer-qa.abrightlab.com/home',
    redirectSignOut:
      'http://localhost:3000/,https://www.customer-dev.abrightlab.com/,https://www.customer-qa.abrightlab.com',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE', 'APPLE'],
  aws_cognito_signup_attributes: ['NAME'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS', 'TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 6,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL', 'PHONE_NUMBER'],
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://bknve4moq5bdlgtcgs4h33msza.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_apiKey: 'da2-ifog7u6mjfatljkbnvmgayacoq',
  aws_user_files_s3_bucket: 'arkstoragedev160717-dev',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_cloud_logic_custom: [
    {
      name: 'getIdsApi',
      endpoint: 'https://api-dev.abrightlab.com/analytics/customerDashboard/submitQuery',
      region: 'us-east-1',
    },
    {
      name: 'getFacilities',
      endpoint: `https://api-dev.abrightlab.com/analytics/customerDashboard/getQueryResults`,
      region: 'us-east-1',
    },
    {
      name: 'taskSummary',
      endpoint: ` https://api-dev.abrightlab.com/analytics/dashboard/taskSummary`,
      region: 'us-east-1',
    },
    {
      name: 'taskPerformance',
      endpoint: ` https://api-dev.abrightlab.com/analytics/dashboard/task-performance`,
      region: 'us-east-1',
    },
    {
      name: 'inspectionByDays',
      endpoint: ` https://api-dev.abrightlab.com/analytics/dashboard/inspection-by-days`,
      region: 'us-east-1',
    },
    {
      name: 'inspectionByLocation',
      endpoint: ` https://api-dev.abrightlab.com/analytics/dashboard/inspection-by-locations`,
      region: 'us-east-1',
    },
    {
      name: 'taskInspection',
      endpoint: ` https://api-dev.abrightlab.com/analytics/dashboard/task-inspection`,
      region: 'us-east-1',
    },
    {
      name: 'paginatedTaskSummary',
      endpoint: `https://api-dev.abrightlab.com/analytics/dashboard/taskSummary/paginated`,
      region: 'us-east-1',
    },
    {
      name: 'noShowFacilities',
      endpoint: `https://api-dev.abrightlab.com/analytics/dashboard/noShowFacilities`,
      region: 'us-east-1',
    },
    {
      name: 'oneTimeJobs',
      endpoint: `https://api-dev.abrightlab.com/analytics/dashboard/oneTimeJobs`,
      region: 'us-east-1',
    },
    {
      name: 'Jobs',
      endpoint: `https://api-dev.abrightlab.com/analytics/dashboard/jobs/finder`,
      region: 'us-east-1',
    },
  ],
};

export default config;
